import type { FC } from "react";
import { useEffect, useMemo, useState } from "react";
import type { FieldProps } from "@rjsf/core";
import { useTranslation } from "react-i18next";
import type { TFunction } from "i18next";
import type { GridSpacing } from "@mui/material";
import { Grid } from "@mui/material";
import { KioSelect } from "@/framework/KioForm/common/KioSelect";
import type { SelectOption } from "@/framework/KioForm/common/KioSelect";
import KioTextField from "@/framework/KioForm/common/KioTextField";
import Styles from "@/assets/styles/Styles";
import Settings from "@/Settings";

export interface DMQuery {
  query?: string;
  options?: {
    includeLink?: boolean;
  };
}

export interface DMQueryFieldProps extends Omit<FieldProps, "formData"> {
  formData?: DMQuery;
}

export enum DMObjects {
  Thing = "Thing",
  Picture = "Picture",
  Architecture = "Architecture",
  Artdesign = "Artdesign",
  Building = "Building",
  Exhibition = "Exhibition",
  Fineart = "Fineart",
  Folder = "Folder",
  Person = "Person",
}

export enum DMSortables {
  Published = "artifact.publishedDate",
  Updated = "artifact.updatedDate",
  Title = "artifact.ingress.title",
  ProductionLocation = "artifact.ingress.production.place",
}

const buildFieldQueryString = (options: string[], identifier: string): string => {
  let value = "";
  for (const option of options) {
    if (!option) continue;
    value += `${value ? "%20OR%20" : ""}${identifier}:${option}`;
  }
  return value ? `fq=(${value})` : "";
};

const getParam = (url?: string, key?: string): string => {
  if (!url || !url.includes("?") || !key) return "";
  const params = new URLSearchParams(url.split("?")[1]);
  return params.get(key) ?? "";
};

const parseOptionValues = (url?: string, key?: string, identifier?: string): string[] => {
  if (!url || !url.includes("?") || !identifier || !key) return [];
  const params = new URLSearchParams(url.split("?")[1]);
  const fields = params.getAll(key);
  for (const field of fields) {
    if (field.includes(identifier)) {
      const regex = new RegExp(`${identifier}:([a-zA-Z-]+)`, "g");
      const tmp = field.match(regex);
      if (!tmp) continue;
      return tmp?.map((val: string) => val.split(`${identifier}:`)[1]);
    }
  }
  return [];
};

const getObjects = (t?: TFunction): SelectOption[] =>
  Object.entries(DMObjects).map(([key, value]) => ({
    label: t?.(`components.DMQueryField.objects.${key}`) ?? "",
    value,
  }));

const getLinks = (t?: TFunction): SelectOption[] => [
  {
    label: t?.("generic.yes") ?? "True",
    value: true,
  },
  {
    label: t?.("generic.no") ?? "False",
    value: false,
  },
];

const getSortOptions = (t?: TFunction): SelectOption[] =>
  Object.entries(DMSortables).map(([key, value]) => ({
    label: t?.(`components.DMQueryField.sortBy.${key}`) ?? "",
    value,
  }));

const getLicenseTypes = (t?: TFunction): SelectOption[] => [
  {
    label: t?.("editMedia.licenses.cc-by-sa") || "CC-by-sa",
    value: "cc-by-sa",
  },
  {
    label: t?.("editMedia.licenses.cc-by-nc") || "CC-by-nc",
    value: "cc-by-nc",
  },
];

// FIXME formData set to empty object as it's undefined when creating new dm search block,
// this seems to resolve KULADM-665, but unsure what the intended functionality is here
export const DMQueryField: FC<DMQueryFieldProps> = ({ formData = {}, onChange, uiSchema }) => {
  const { t } = useTranslation("common");
  const [chosenEntities, setChosenEntities] = useState<string[]>();
  const [includeLink, setIncludeLink] = useState<boolean>(false);
  const [ownerCode, setOwnerCode] = useState("");
  const [tags, setTags] = useState("");
  const [licenses, setLicenses] = useState<string[]>();
  const [query, setQuery] = useState("");
  const [sortBy, setSortBy] = useState<string[]>([DMSortables.Published]);
  const [hasLoaded, setHasLoaded] = useState(false);

  const spacing: GridSpacing = useMemo(() => {
    const isValidSpacing = (value: unknown): boolean => typeof value === "number" && value > -1 && value < 13;
    if (isValidSpacing(uiSchema?.spacing)) return uiSchema.spacing;
    if (isValidSpacing(Styles.Dimensions.FORM_FIELD_ROW_FIELDS_SPACING)) {
      return Styles.Dimensions.FORM_FIELD_ROW_FIELDS_SPACING;
    }
    return 0;
  }, [uiSchema]);

  const formFields = [
    <KioTextField
      name="query"
      key="query"
      aria-label="query"
      label={t(`components.DMQueryField.query`)}
      value={query ?? ""}
      onChange={(e) => setQuery(e.target.value)}
    />,
    <KioTextField
      name="ownerCode"
      key="ownerCode"
      aria-label="ownerCode"
      value={ownerCode ?? ""}
      label={t(`components.DMQueryField.ownerCode`)}
      onChange={(e) => setOwnerCode(e.target.value)}
    />,
    <KioSelect
      multiple
      aria-label="objectTypes"
      name="objectTypes"
      key="objectTypes"
      label={t(`components.DMQueryField.objectTypes`)}
      data-testid="objectTypes"
      options={getObjects(t)}
      value={chosenEntities ?? []}
      onChange={setChosenEntities}
    />,
    <KioSelect
      label={t(`components.DMQueryField.includeLink`)}
      name="includeLink"
      key="includeLink"
      aria-label="includeLink"
      value={[includeLink]}
      onChange={setIncludeLink ?? ""}
      options={getLinks(t)}
    />,
    <KioSelect
      label={t(`components.DMQueryField.sortBy.label`)}
      name="sortBy"
      key="sortBy"
      aria-label="sortBy"
      data-testid="sortBy"
      value={sortBy}
      onChange={setSortBy ?? []}
      options={getSortOptions(t)}
    />,
    <KioTextField
      name="tags"
      key="tags"
      aria-label="tags"
      value={tags}
      label={t(`components.DMQueryField.tags`)}
      onChange={(e) => setTags(e.target.value)}
    />,
    <KioSelect
      multiple
      name="license"
      key="license"
      aria-label="license"
      value={licenses ?? []}
      options={getLicenseTypes(t)}
      label={t(`components.DMQueryField.license`)}
      onChange={setLicenses}
    />,
  ];

  const query_data = useMemo(() => {
    let queryString = `${Settings.DM_SOLR}?wt=json`; // add "&api.key=demo" here to test query strings directly
    const params: string[] = [];
    let options: DMQuery["options"];
    if (query) {
      params.push(`q=allContent:${query}`);
    }
    if (sortBy) {
      if (sortBy.includes(DMSortables.Updated) || sortBy.includes(DMSortables.Published)) {
        params.push(`sort=${sortBy}%20desc`);
      } else params.push(`sort=${sortBy}%20asc`);
    }
    if (chosenEntities) {
      params.push(buildFieldQueryString(chosenEntities, "artifact.type"));
    }
    if (ownerCode) params.push(`fq=(identifier.owner:${ownerCode})`);
    if (tags) {
      const values = tags.replace(/\s+/g, "").split(",");
      params.push(buildFieldQueryString(values, "artifact.ingress.subjects"));
    }
    if (licenses) {
      params.push(buildFieldQueryString(licenses, "artifact.ingress.license"));
    }
    options = {
      includeLink,
    };
    for (const param of params) {
      queryString += `&${param}`;
    }
    return { options, queryString };
  }, [chosenEntities, sortBy, tags, includeLink, licenses, ownerCode, query]);

  useEffect(() => {
    if (formData.query !== query_data.queryString && formData.options?.includeLink !== query_data.options?.includeLink)
      onChange?.({ query: query_data.queryString, options: query_data.options });
  }, [query_data, onChange]);

  useEffect(() => {
    if (hasLoaded || !formData) return;
    setQuery(getParam(formData?.query, "q"));
    const sortBy = getParam(formData?.query, "sort");
    if ((Object as any).values(DMSortables).includes(sortBy)) {
      setSortBy([sortBy]);
    }
    setOwnerCode(parseOptionValues(formData.query, "fq", "identifier.owner")[0]);
    setChosenEntities(parseOptionValues(formData?.query, "fq", "artifact.type"));
    setLicenses(parseOptionValues(formData?.query, "fq", "artifact.ingress.license"));
    setTags(parseOptionValues(formData?.query, "fq", "artifact.ingress.subjects").join(", "));
    formData?.options?.includeLink === true ? setIncludeLink(true) : setIncludeLink(false);
    setHasLoaded(true);
  }, [formData, hasLoaded]);

  return (
    <Grid container spacing={spacing}>
      {formFields.map((field: JSX.Element, i: number) => (
        <Grid key={field.props.name} item lg={i === 0 ? 12 : 6} sm={12}>
          {field}
        </Grid>
      ))}
    </Grid>
  );
};
