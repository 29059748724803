import type { Widget } from "@rjsf/core";
import KioTextareaWidget from "./KioTextareaWidget";
import EntityAutocompleteWidget from "./EntityAutocompleteWidget";
import JsonEditorWidget from "./JsonEditorWidget";
import ActionQueryEditorWidget from "./ActionQueryEditorWidget";
import TextAreaWysiwygWidget from "./TextAreaWysiwygWidget";
import SwitchWidget from "./SwitchWidget";
import { SelectWidget } from "./SelectWidget";
import KioDateTimeWidget from "./KioDateTimeWidget";
import HelperTextWidget from "./HelperTextWidget";
import KioTextWidget from "@/framework/KioForm/widgets/KioTextWidget";
import MuiIconWidget from "@/framework/KioForm/widgets/MuiIconWidget";
import GridSelectWidget from "@/framework/KioForm/widgets/GridSelectWidget";
import KioRangeWidget from "@/framework/KioForm/widgets/KioRangeWiget";
import ColorWidget from "@/framework/KioForm/widgets/ColorWidget";

const Widgets: { [widgetName: string]: Widget } = {
  JsonEditorWidget,
  EntityAutocompleteWidget,
  ActionQueryEditorWidget,
  TextAreaWysiwygWidget,
  SwitchWidget,
  SelectWidget,
  TextWidget: KioTextWidget,
  TextareaWidget: KioTextareaWidget,
  DateTimeWidget: KioDateTimeWidget,
  MuiIconWidget,
  HelperTextWidget,
  RangeWidget: KioRangeWidget,
  ColorWidget,
  GridSelectWidget,
};

const WhiteListedWidgets: string[] = [
  "alt-date-time",
  "text",
  "radio",
  "select",
  "textarea",
  "password",
  "color",
  "up-down",
  "range",
  "url",
  "hidden",
  "file",
  "email",
  "date",
  "date-time",
  "checkbox",
  "checkboxes",
  "alt-date",
];

export { Widgets, WhiteListedWidgets };
