import type { FC, MouseEventHandler } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert, Box, Button, Typography } from "@mui/material";
import { Folder, Info, Refresh } from "@mui/icons-material";
import type { ModelViewerElement } from "@google/model-viewer";
import type { MediaData } from "@/declarations/models/Media";
import type Media from "@/declarations/models/Media";
import { useStore } from "@/Store";
import Api from "@/services/Api";
import { useMediaSelector } from "@/components/MediaSelector/MediaSelector";
import MediaType from "@/declarations/models/MediaType";
import Styles from "@/assets/styles/Styles";
import Image from "@/components/Image";
import Loader from "@/framework/Loader";

export const Capture3DModelAndUploadThumbnail: FC<{
  media: Media;
  mediaData: MediaData;
  registerBeforeSubmitHandler: (callback: ((media: Media) => Promise<Media>) | null) => void;
  modelViewerId?: string;
}> = ({ media, mediaData, registerBeforeSubmitHandler, modelViewerId }) => {
  const [initialThumbnailMedia, setInitialThumbnailMedia] = useState<Media | null>(null);
  const [preview, setPreview] = useState<Blob | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);
  const { t } = useTranslation("common");
  const { state } = useStore();
  const ownerId: number | undefined = state?.cmsContextInstance?.owner_id;
  const instanceId: number | undefined = state?.cmsContextInstance?.id;
  const [selectedMediaFromSelector, setSelectedMediaFromSelector] = useState<Media | null>(null);

  const currentThumbnailMediaId = mediaData.settings?.thumbnail_override?.mediaId;
  useEffect(() => {
    if (currentThumbnailMediaId) {
      Api.getOneMedia(currentThumbnailMediaId)
        .fetchDirect(null)
        .then((media) => {
          setInitialThumbnailMedia(media);
        });
    }
  }, [currentThumbnailMediaId]);

  const { openMediaDrawer, closeMediaDrawer } = useMediaSelector();

  const handleMediaSelected = (mediaFromSelector: Media) => {
    setPreview(null);
    setSelectedMediaFromSelector(mediaFromSelector);
    if (mediaFromSelector.id === currentThumbnailMediaId) {
      registerBeforeSubmitHandler(null);
    } else {
      registerBeforeSubmitHandler(async (media: Media) =>
        Promise.resolve({
          ...media,
          thumbnail_override: {
            mediaId: mediaFromSelector.id!,
          },
        })
      );
    }
    closeMediaDrawer();
  };

  const handleOpenMediaDrawer = () => {
    openMediaDrawer([MediaType.IMAGE], handleMediaSelected, false);
  };

  const capture: MouseEventHandler = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    const modelViewer = document.getElementById(modelViewerId ?? `model-viewer-${media.identifier}`) as
      | ModelViewerElement
      | undefined;
    if (modelViewer) {
      setPreview(await modelViewer.toBlob({ idealAspect: true }));
      setSelectedMediaFromSelector(null);

      registerBeforeSubmitHandler(async (media: Media) => {
        setUploading(true);
        const fileName: string = t("editMedia.generatedModel3DThumbnail", { modelName: media.name });
        const createdMedia = await Api.uploadToDMS(
          await modelViewer.toBlob({ idealAspect: true }),
          ownerId,
          instanceId,
          fileName
        ).fetchDirect(null);
        setUploading(false);
        if (createdMedia) {
          return {
            ...media,
            thumbnail_override: {
              mediaId: createdMedia.id!,
            },
          };
        }
        return media;
      });
    } else {
      console.error("No model-viewer found for media with identifier: ", media.identifier);
    }
  };

  const reset = () => {
    setPreview(null);
    setSelectedMediaFromSelector(null);
    registerBeforeSubmitHandler(null);
  };

  const thumbnailSize = 160;

  function renderPreview() {
    if (preview) {
      return (
        <Image
          src={URL.createObjectURL(preview)}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      );
    }
    if (selectedMediaFromSelector) {
      return (
        <Image
          src={`${selectedMediaFromSelector.src}`}
          isWebPFormat={true}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      );
    }
    if (initialThumbnailMedia) {
      return (
        <Image
          src={`${initialThumbnailMedia.src}`}
          isWebPFormat={true}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      );
    }
    return (
      <Typography
        variant="subtitle2"
        sx={{
          m: 1,
        }}
      >
        {t("generic.none")}
      </Typography>
    );
  }

  return (
    <Box mb={2}>
      <Box mt={2} display="flex" gap={2} minHeight={thumbnailSize}>
        <Box height={thumbnailSize}>
          <Box
            sx={{
              width: thumbnailSize,
              height: thumbnailSize,
              border: `1px solid ${Styles.Colors.PREVIEW_BORDER_COLOR}`,
            }}
          >
            {renderPreview()}
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          <Alert severity="info" icon={<Info />}>
            <Typography color={Styles.Colors.THEME_PRIMARY}>{t("editMedia.generateThumbnailInfo1")}</Typography>
          </Alert>

          <Box display="flex" gap={1}>
            <Button
              variant="outlined"
              onClick={capture}
              startIcon={<Refresh />}
              sx={{
                flex: 1,
              }}
            >
              {t("editMedia.generateFromModel")}
            </Button>
            <Button
              variant="outlined"
              onClick={handleOpenMediaDrawer}
              startIcon={<Folder />}
              sx={{
                flex: 1,
              }}
            >
              {t("editMedia.findMedia")}
            </Button>
          </Box>
          <Button
            variant="text"
            onClick={reset}
            sx={{
              alignSelf: "flex-end",
            }}
          >
            {t("generic.reset")}
          </Button>
        </Box>
      </Box>
      {uploading && <Loader />}
    </Box>
  );
};
