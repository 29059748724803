import type { FC } from "react";
import type { ObjectFieldTemplateProps } from "@rjsf/core";
import GridTemplateContainer from "@/framework/KioForm/common/GridTemplateContainer";
import { hasAnyErrors, splitHeaderAndBodyContent } from "@/utils/form";

const ModuleObjectTemplate: FC<ObjectFieldTemplateProps> = ({ uiSchema, properties, schema }) => {
  const headerAndBodyContent = splitHeaderAndBodyContent(properties, schema, uiSchema);
  const hasFormErrors = properties.some((child) => hasAnyErrors(child?.content?.props?.errorSchema));

  return <GridTemplateContainer>{headerAndBodyContent.body.map((prop) => prop.content)}</GridTemplateContainer>;
};

export default ModuleObjectTemplate;
