import type { FC } from "react";
import { useCallback } from "react";
import { Typography } from "@mui/material";
import { utils } from "@rjsf/core";
import { useTranslation } from "react-i18next";
import DocumentSelector from "@/components/DocumentSelector/DocumentSelector";
import KioTextField from "@/framework/KioForm/common/KioTextField";
import type { RelationFieldOptions } from "@/framework/KioForm/fields/DocumentRelationField";
import type { KioFieldProps } from "@/framework/KioForm/KioFieldProps";
import type { KioFormContext } from "@/framework/KioForm/KioForm";
import { getSchemaTitle } from "@/utils/schema";

const SingleRelationField: FC<KioFieldProps & { showDocumentSelector: boolean }> = ({
  uiSchema,
  idSchema,
  formData,
  errorSchema,
  formContext,
  registry,
  onChange,
  onTitleChange,
  showDocumentSelector,
  ...props
}) => {
  const schema = utils.retrieveSchema(props.schema, registry.definitions, formData);

  const { t } = useTranslation("common");

  const title = getSchemaTitle(formContext, schema, uiSchema, formData);
  const description = schema["description"] || uiSchema["ui:description"];

  const uiOptions = uiSchema["ui:options"] as any as RelationFieldOptions;

  const onDocumentSelected = useCallback(
    (document_id: number | null) => {
      if (!document_id) return;
      onChange({
        ...formData,
        to_document_id: document_id,
      });
    },
    [onChange]
  );
  const onReferenceKeyChange = (newValue: string | number) => {
    onChange({
      ...formData,
      reference_key: String(newValue),
    });
  };

  const onContentChange = (content: any) => {
    onChange({
      ...formData,
      content,
    });
  };

  const { SchemaField } = registry.fields;
  const disabledContent = !formData?.["to_document_id"] || !formData?.["reference_key"];
  const content = schema?.properties?.["content"];

  return (
    <>
      {title && (
        <Typography variant="h5" component="div">
          {title}
        </Typography>
      )}
      {description && (
        <Typography variant="body1" component="div">
          {description}
        </Typography>
      )}
      {showDocumentSelector && (
        <DocumentSelector
          multiple={false}
          uiOptions={uiOptions}
          value={formData?.to_document_id || -1}
          onSelect={onDocumentSelected}
          selectedLanguage={(formContext as KioFormContext).selectedLocale}
          onTitleChange={onTitleChange}
        />
      )}
      <div style={uiOptions.displayReferenceKey ? undefined : { display: "none" }}>
        <KioTextField
          id={idSchema["reference_key"].$id}
          title={t("kioForm.fields.DocumentRelationField.referenceKey")}
          name={"reference_key"}
          value={formData?.reference_key || ""}
          onChange={(e) => onReferenceKeyChange(e.target.value)}
        />
      </div>
      {!!content && typeof content !== "boolean" && (
        <div style={uiOptions.displayContentField ? undefined : { display: "none" }}>
          <SchemaField
            readonly={false}
            required={false}
            disabled={disabledContent}
            autofocus={false}
            key={`${props.id}_${props.name}_${JSON.stringify(formData || {})}`}
            schema={content}
            uiSchema={uiSchema["content"]}
            idSchema={idSchema["content"]}
            formData={(formData || {})["content"]}
            errorSchema={errorSchema["content"]}
            registry={registry}
            formContext={formContext}
            onChange={onContentChange}
            onBlur={props.onBlur}
            onFocus={props.onFocus}
            name={title}
          />
        </div>
      )}
    </>
  );
};

export default SingleRelationField;
