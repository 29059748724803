import type { JSONSchema7 } from "json-schema";
import type { UiSchema } from "@rjsf/core";
import { baseJsonSchema, baseUiSchema } from "@/views/cms/EditorView/mediaSchemas/baseMediaSchema";

const tempJson = JSON.parse(JSON.stringify(baseJsonSchema)) as JSONSchema7;
export const model3dJsonSchema: JSONSchema7 = tempJson;

const tempUi = JSON.parse(JSON.stringify(baseUiSchema)) as UiSchema;
tempUi["ui:order"] = ["general", "metaData", "credits"];
export const model3dUiSchema: UiSchema = tempUi;
