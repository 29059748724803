import type { FieldProps } from "@rjsf/core";
import type { FC } from "react";
import { Box } from "@mui/material";
import { KulturnavAutocomplete } from "@/components/KulturnavAutocomplete";
import KioTitle from "@/framework/KioForm/common/KioTitle";
import { getValueFromUiSchema } from "@/utils/schema";

export interface KulturnavFieldObject {
  tags: {
    kulturnavUuid: string;
  }[];
}

export interface KulturnavAutocompleteFieldProps extends Omit<FieldProps, "formData"> {
  formData?: KulturnavFieldObject;
}

export const KulturnavAutocompleteField: FC<KulturnavAutocompleteFieldProps> = ({
  formData,
  onChange,
  uiSchema,
  formContext,
}) => {
  const selectedLocale = formContext?.selectedLocale;
  const ids = formData?.tags?.map((tag) => tag.kulturnavUuid).filter(Boolean) || [];

  const title = getValueFromUiSchema("title", uiSchema);
  const description = getValueFromUiSchema("description", uiSchema);

  const entityType = getValueFromUiSchema("entityType", uiSchema);
  const propertyType = getValueFromUiSchema("propertyType", uiSchema);
  const dataset = getValueFromUiSchema("dataset", uiSchema);

  return (
    <Box>
      <KioTitle title={title} description={description} level={2} />
      <KulturnavAutocomplete
        value={ids}
        onChange={(ids) => onChange({ tags: ids.map((kulturnavUuid) => ({ kulturnavUuid })) })}
        entityType={entityType}
        propertyType={propertyType}
        dataset={dataset}
        locale={selectedLocale ?? "no"}
      />
    </Box>
  );
};
