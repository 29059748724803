import type { JSONSchema7 } from "json-schema";
import type { UiSchema } from "@rjsf/core";

const CATEGORIES_OTHER = ["", "photograph", "illustration", "painting", "vector", "other", "music", "spoken", "sound"];

const baseMediaMetaDataSubSchema: { [key: string]: any } = {
  license: {
    type: "string",
  },
  tags: {
    type: "array",
    default: [],
    minItems: 0,
    items: {
      type: "string",
    },
  },
  category: {
    enum: CATEGORIES_OTHER,
  },
  year: {
    type: ["integer", "null"],
    minimum: 0,
    maximum: 9999,
  },
};

export const baseJsonSchema: JSONSchema7 = {
  type: "object",
  properties: {
    general: {
      type: "object",
      properties: {
        name: {
          type: "string",
          minLength: 1,
        },
        title: {
          type: "object",
          patternProperties: {
            "^[a-zA-Z]{2}$": {
              type: "string",
            },
          },
        },
        description: {
          type: "object",
          patternProperties: {
            "^[a-zA-Z]{2}$": {
              type: "string",
            },
          },
        },
        alt_text: {
          type: "object",
          patternProperties: {
            "^[a-zA-Z]{2}$": {
              type: "string",
            },
          },
        },
      },
      required: ["name"],
    },

    metaData: {
      type: "object",
      properties: {
        category: {
          type: "string",
        },
      },
      dependencies: {
        category: {
          oneOf: [
            {
              $ref: "#/definitions/metaDataOther",
            },
            {
              $ref: "#/definitions/metaDataMovie",
            },
            {
              $ref: "#/definitions/metaDataMusicVideo",
            },
            {
              $ref: "#/definitions/metaDataTV",
            },
          ],
        },
      },
    },

    credits: {
      type: "array",
      items: {
        type: "object",
        properties: {
          credit_type_id: {
            type: "integer",
          },
          name: {
            type: "string",
            minLength: 1,
          },
        },
        required: ["credit_type_id", "name"],
      },
    },
  },
  definitions: {
    metaDataOther: {
      properties: {
        ...JSON.parse(JSON.stringify(baseMediaMetaDataSubSchema)),
        category: {
          enum: CATEGORIES_OTHER,
        },
      },
    },
    metaDataMovie: {
      properties: {
        ...JSON.parse(JSON.stringify(baseMediaMetaDataSubSchema)),
        category: {
          enum: ["movie"],
        },
        locationShot: {
          type: "string",
        },
        locationShown: {
          type: "string",
        },
      },
    },
    metaDataMusicVideo: {
      properties: {
        ...JSON.parse(JSON.stringify(baseMediaMetaDataSubSchema)),
        category: {
          enum: ["musicVideo"],
        },
        albumTitle: {
          type: "string",
        },
        catalogNumber: {
          type: "string",
        },
      },
    },
    metaDataTV: {
      properties: {
        ...JSON.parse(JSON.stringify(baseMediaMetaDataSubSchema)),
        category: {
          enum: ["tv"],
        },
        series: {
          type: "string",
        },
        season: {
          type: "string",
        },
        episode: {
          type: "string",
        },
        locationShot: {
          type: "string",
        },
        locationShown: {
          type: "string",
        },
      },
    },
  },
};

export const baseUiSchema: UiSchema = {
  "ui:order": ["general", "metaData", "credits"],
  general: {
    "ui:title": "generic.general",
    name: {
      "ui:title": "generic.internal_title",
    },
    title: {
      items: {
        "ui:title": "generic.title",
      },
      "ui:field": "LocalizedField",
    },
    description: {
      "ui:field": "LocalizedField",
      items: {
        "ui:title": "generic.description",
        "ui:widget": "TextareaWidget",
      },
    },
    alt_text: {
      "ui:field": "LocalizedField",
      items: {
        "ui:title": "editMedia.alt_text",
        "ui:widget": "TextareaWidget",
      },
    },
    "ui:layout": {
      fields: ["name", "title", "description", "alt_text"],
      template: "Section",
      templateOptions: {
        default: {
          expanded: true,
        },
      },
    },
  },

  metaData: {
    "ui:title": "editMedia.metadata",
    license: {
      "ui:title": "editMedia.licenseType",
      "ui:widget": "SelectWidget",
      "ui:options": {
        choices: [
          { value: "", "ui:label": "editMedia.licenses.none" },
          { value: "cc", "ui:label": "editMedia.licenses.copyright" },
          { value: "cc-by-nc-nd", "ui:label": "editMedia.licenses.cc-by-nc-nd" },
          { value: "cc-by-nd", "ui:label": "editMedia.licenses.cc-by-nd" },
          { value: "cc-by-nc-sa", "ui:label": "editMedia.licenses.cc-by-nc-sa" },
          { value: "cc-by-nc", "ui:label": "editMedia.licenses.cc-by-nc" },
          { value: "cc-by-sa", "ui:label": "editMedia.licenses.cc-by-sa" },
          { value: "cc-by", "ui:label": "editMedia.licenses.cc-by" },
          { value: "pdm", "ui:label": "editMedia.licenses.pdm" },
          { value: "pdd", "ui:label": "editMedia.licenses.pdd" },
        ],
      },
    },
    tags: {
      "ui:title": "editMedia.tags",
      "ui:field": "ChipList",
      "ui:options": {
        deletable: true,
      },
    },
    category: {
      "ui:title": "generic.category",
      "ui:colspan": 10,
      "ui:widget": "SelectWidget",
      "ui:options": {},
    },
    year: {
      "ui:title": "generic.year",
      "ui:colspan": 2,
    },
    series: {
      "ui:title": "editMedia.additionalMetaData.series",
    },
    season: {
      "ui:title": "editMedia.additionalMetaData.season",
    },
    episode: {
      "ui:title": "editMedia.additionalMetaData.episode",
    },
    locationShot: {
      "ui:title": "editMedia.additionalMetaData.locationShot",
      "ui:widget": "TextareaWidget",
    },
    locationShown: {
      "ui:title": "editMedia.additionalMetaData.locationShown",
      "ui:widget": "TextareaWidget",
    },
    albumTitle: {
      "ui:title": "editMedia.additionalMetaData.albumTitle",
    },
    catalogNumber: {
      "ui:title": "editMedia.additionalMetaData.catalogNumber",
    },
    "ui:layout": {
      fields: [
        "license",
        "tags",
        "category",
        "year",
        "series",
        "season",
        "episode",
        "locationShot",
        "locationShown",
        "albumTitle",
        "catalogNumber",
      ],
      template: "Section",
    },
  },

  credits: {
    "ui:title": "editMedia.credits",
    items: {
      "ui:options": {
        titleProp: "name",
      },
      credit_type_id: {
        "ui:title": "generic.type",
        "ui:widget": "EntityAutocompleteWidget",
        "ui:options": {
          type: "CreditType",
          valueField: "id",
          displayField: "name",
        },
      },
      name: {
        "ui:title": "generic.name",
      },
    },
  },
};
