import type { FC, IframeHTMLAttributes } from "react";
import { useState } from "react";
import { styled } from "@mui/material";
import Styles from "@/assets/styles/Styles";

export interface EmbeddedContentProps
  extends Omit<IframeHTMLAttributes<HTMLIFrameElement>, "src" | "title" | "frameBorder" | "width" | "height"> {
  src: string;
  title: string;
  invalid?: boolean;
  fallbackImageSrc?: string;
}

const IFrame = styled("iframe")({
  border: "none",
  width: Styles.Dimensions.MEDIA_PREVIEW_MEDIA_PLAYER_WIDTH,
  height: Styles.Dimensions.MEDIA_PREVIEW_MEDIA_PLAYER_HEIGHT,
});

const FallbackContainer = styled("div")({
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: `1px solid ${Styles.Colors.EMBEDDED_CONTENT_PREVIEW_FALLBACK_BORDER_COLOR}`,
  width: Styles.Dimensions.MEDIA_PREVIEW_MEDIA_PLAYER_WIDTH,
  height: Styles.Dimensions.MEDIA_PREVIEW_MEDIA_PLAYER_HEIGHT,
});

const FallbackLogo = styled("img")({
  maxWidth: "50%",
  maxHeight: "50%",
  objectFit: "contain",
});

export const EmbeddedContent: FC<EmbeddedContentProps> = ({
  children,
  src,
  title,
  invalid,
  onError,
  fallbackImageSrc,
  ...iFrameProps
}) => {
  const [loadError, setLoadError] = useState<boolean>(false);
  if (invalid || loadError) {
    return (
      <FallbackContainer>
        {!!fallbackImageSrc ? <FallbackLogo src={fallbackImageSrc} alt="Logo" /> : children}
      </FallbackContainer>
    );
  }
  return (
    <IFrame
      {...iFrameProps}
      title={title}
      src={src}
      onError={(e) => {
        setLoadError(true);
        onError?.(e);
      }}
    />
  );
};

export default EmbeddedContent;
