import type { WidgetProps } from "@rjsf/core";
import type { FC } from "react";
import styled from "@emotion/styled";
import { getValueFromUiSchema } from "@/utils/schema";

const Heading = styled.div`
  font-weight: 700;
`;

const HelperTextWidget: FC<WidgetProps> = ({ uiSchema }) => {
  const title = getValueFromUiSchema("title", uiSchema);
  const description = getValueFromUiSchema("description", uiSchema);

  return (
    <>
      {title && <Heading>{title}</Heading>}
      {description && <div>{description}</div>}
    </>
  );
};

export default HelperTextWidget;
