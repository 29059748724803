import type { FC } from "react";
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import Toast from "./Toast";

/**
 * Set the severity of the message.
 */
export enum Severity {
  INFO = "info",
  SUCCESS = "success",
  WARNING = "warning",
  ERROR = "error",
}

/**
 *
 */
interface Message {
  message: string;
  severity: Severity;
  duration: number;
}

/**
 *
 */
export interface MessengerProviderProps {
  /**
   * How many MS from a message ends, until the next message is displayed, if any.
   */
  children: any;
  betweenMessageDelayMs?: number;
}

/**
 * The API to expose through the useMessenger-hook
 */
interface MessengerContextValue {
  pushMessage: (messages: string, severity: Severity, duration: number) => void;
  info: (message: string, duration?: number) => void;
  success: (message: string, duration?: number) => void;
  warning: (message: string, duration?: number) => void;
  error: (message: string, duration?: number) => void;
}

/**
 *
 */
const MessengerContext = createContext<MessengerContextValue>({
  pushMessage: () => console.error("Messenger not initialized"),
  info: () => console.error("Messenger not initialized"),
  success: () => console.error("Messenger not initialized"),
  warning: () => console.error("Messenger not initialized"),
  error: () => console.error("Messenger not initialized"),
});

/**
 * Provides an API to broadcast system-wide messages to the user
 */
export const useMessenger = (): MessengerContextValue => useContext(MessengerContext);

const DEFAULT_INFO_DURATION_MS = 5000;
const DEFAULT_SUCCESS_DURATION_MS = 3000;
const DEFAULT_WARNING_DURATION_MS = 8000;
const DEFAULT_ERROR_DURATION_MS = 10000;

const MESSAGES_KEY = "kit.system_messages";

const getPersistedMessages = (): Array<Message> => {
  const storedMessages: string | null = window.sessionStorage.getItem(MESSAGES_KEY);
  return storedMessages ? JSON.parse(storedMessages) : [];
};

const persistMessages = (messages: Array<Message>): void => {
  window.sessionStorage.setItem(MESSAGES_KEY, JSON.stringify(messages || []));
};

/**
 *
 * @param children
 * @param betweenMessageDelayMs
 * @param messageDurationTimeMs
 * @constructor
 */
export const Messenger: FC<MessengerProviderProps> = ({ children, betweenMessageDelayMs = 250 }) => {
  const delayTimerId = useRef<NodeJS.Timeout | null>(null);
  const [messages, setMessages] = useState<Array<Message>>(getPersistedMessages());
  const [currentMessage, setCurrentMessage] = useState<Message | null>(null);

  const pushMessage = useCallback<MessengerContextValue["pushMessage"]>(
    (message: string, severity: Severity, duration: number) => {
      setMessages((m) => {
        const newMessages = [...m, { message, severity, duration }];
        persistMessages(newMessages);
        return newMessages;
      });
    },
    []
  );

  const contextValue = useMemo<MessengerContextValue>(
    () => ({
      pushMessage,
      info: (message, duration) => pushMessage(message, Severity.INFO, duration || DEFAULT_INFO_DURATION_MS),
      success: (message, duration) => pushMessage(message, Severity.SUCCESS, duration || DEFAULT_SUCCESS_DURATION_MS),
      warning: (message, duration) => pushMessage(message, Severity.WARNING, duration || DEFAULT_WARNING_DURATION_MS),
      error: (message, duration) => pushMessage(message, Severity.ERROR, duration || DEFAULT_ERROR_DURATION_MS),
    }),
    [pushMessage]
  );

  const handleMessageCompleted = () => {
    if (messages.length === 0) {
      // No more messages: Unset the message being displayed
      setCurrentMessage(null);
      return;
    }
    if (!!currentMessage) {
      // Setup timer for next message, before unsetting the current message
      delayTimerId.current = setTimeout(() => {
        const [next, ...rest] = messages;
        setCurrentMessage(next);
        setMessages(rest);
        persistMessages(rest);
        delayTimerId.current = null;
      }, betweenMessageDelayMs);
      setCurrentMessage(null);
    }
  };

  useEffect(() => {
    if (!currentMessage && !delayTimerId.current && messages.length > 0) {
      // One message was added to an empty queue; Display it right away
      const [next, ...rest] = messages;
      setCurrentMessage(next);
      setMessages(rest);
      persistMessages(rest);
    }
  }, [messages, currentMessage]);

  useEffect(
    () => () => {
      // Clear any active timer if the Messenger dismounts before it completes
      delayTimerId.current !== null && clearTimeout(delayTimerId.current);
    },
    []
  );

  return (
    <MessengerContext.Provider value={contextValue}>
      {children}
      {currentMessage && (
        <Toast
          message={currentMessage.message}
          severity={currentMessage.severity}
          autoHideDuration={currentMessage.duration}
          onClose={handleMessageCompleted}
        />
      )}
    </MessengerContext.Provider>
  );
};

export default Messenger;
