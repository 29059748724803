import type { JSONSchema7 } from "json-schema";
import type { UiSchema } from "@rjsf/core";
import { baseJsonSchema, baseUiSchema } from "@/views/cms/EditorView/mediaSchemas/baseMediaSchema";

const tempJson = JSON.parse(JSON.stringify(baseJsonSchema)) as JSONSchema7;
delete tempJson?.properties?.credits;
export const DMJsonSchema: JSONSchema7 = tempJson;

const tempUi = JSON.parse(JSON.stringify(baseUiSchema)) as UiSchema;
delete tempUi.credits;
export const DMUiSchema: UiSchema = tempUi;
