import type { UiSchema } from "@rjsf/core";

export const uiSchema: UiSchema = {
  "ui:title": "schemaTypes.api-key",
  id: {
    "ui:title": "generic.id",
    "ui:readonly": true,
  },
  key: {
    "ui:title": "generic.key",
    "ui:readonly": true,
  },
  name: {
    "ui:title": "generic.name",
  },
  description: {
    "ui:title": "generic.description",
    "ui:widget": "textarea",
  },
  application_id: {
    "ui:title": "generic.application",
    "ui:widget": "EntityAutocompleteWidget",
    "ui:options": {
      type: "Application",
      valueField: "id",
      displayField: "name",
    },
  },
};
