import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, Collapse, Divider, List, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import type { FC, ReactNode } from "react";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import type { SideMenuItemDef } from "@/components/SideMenu/SideMenu";
import { MuiIcon } from "@/components/MuiIcon";

export interface SideMenuItemProps {
  item: SideMenuItemDef;
}

export const SideMenuItem: FC<SideMenuItemProps> = ({ item }) => {
  const { pathname, search } = useLocation();
  const [expanded, setExpanded] = useState<boolean>(false);

  const path = `${pathname}${search}`;
  const isSelected = path.includes(item.path);

  const handleToggleExpand = () => {
    setExpanded((o) => !o);
  };

  const renderItemContent = (): ReactNode => (
    <>
      {item.icon && (
        <ListItemIcon sx={{ minWidth: "24px" }}>
          <MuiIcon className="material-icons-outlined">{item.icon}</MuiIcon>
        </ListItemIcon>
      )}
      <ListItemText disableTypography inset={!item.icon} sx={{ paddingLeft: `${item.icon ? "8px" : "32px"}` }}>
        <Typography variant="body1">{item.label}</Typography>
      </ListItemText>
    </>
  );

  if (!!item.subItems?.length) {
    return (
      <Box bgcolor="background.default">
        <ListItemButton onClick={handleToggleExpand} dense role={"button"}>
          {renderItemContent()}
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={expanded} timeout="auto">
          <List>
            {item.subItems.map((child, i) => (
              <SideMenuItem key={`sub-item-${i}-${child.label}`} item={child} />
            ))}
          </List>
          <Divider />
        </Collapse>
      </Box>
    );
  }
  return (
    <ListItemButton component={Link} to={item.path} selected={isSelected} dense role={"button"}>
      {renderItemContent()}
    </ListItemButton>
  );
};

export default SideMenuItem;
