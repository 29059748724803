import type { TFunction } from "i18next";
import type { FC } from "react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import type { DataListProps } from "@/components/DataList/DataList";
import DataList from "@/components/DataList/DataList";
import type { DataListRow } from "@/components/DataList/DataListRow";
import type { CreateNewButton, SearchProp } from "@/components/DataList/ListHeader/DataListHeader";
import type { SortOptions } from "@/components/DataList/ListHeader/SortSelector";
import { filterNull, getSchemaName } from "@/components/DocumentSelector/DocumentSelector";
import type ApplicationInstance from "@/declarations/models/ApplicationInstance";
import type { Document } from "@/declarations/models/Document";
import DocumentStatus from "@/declarations/models/DocumentStatus";
import type Menu from "@/declarations/models/Menu";
import type Schema from "@/declarations/models/Schema";
import type { SelectOption } from "@/framework/KioForm/common/KioSelect";
import Loader from "@/framework/Loader";
import { useAsyncSafeState } from "@/hooks/useAsyncSafeState";
import { useDebounce } from "@/hooks/useDebounce";
import { useLoadingState } from "@/hooks/useLoadingState";
import Api from "@/services/Api";
import { cancellablePromise } from "@/utils/async";
import { getValue, isObject, resolvePotentiallyLocalizedString } from "@/utils/obj";
import { getQueryParams } from "@/utils/url";
import type { FinderConfigurationQueryParams, FinderProps } from "@/views/cms/FinderView";
import { defaultFinderQueryParamsValues } from "@/views/cms/FinderView";
import type { CustomButton } from "@/components/DataList/common/DataListOptions";
import { isNumeric_no_whitespaces } from "@/utils/numbers";
import { useSortAndFilterState } from "@/hooks/useSortAndFilterState";

interface DocumentFinderProps extends FinderProps {
  schemaId?: number;
  appMenus?: Menu[];
  singleDocument?: boolean;
  customBatchButtons?: CustomButton[];
}

const parseCodeStringFromDescription = (codeStr: string, item: Document) => {
  const path = codeStr
    .replaceAll(/(\$\{)|(\})/g, "")
    .split(/[\.\[\]]/)
    .filter((el) => el !== "");

  /*
    Parses paths of objects and arrays. Returns 0 length of undefined. Else "" if undefined
    acc["length"] is valid js 🤨
   */
  const parsedCode = path?.reduce((acc, current) => {
    if ((acc as unknown as string) === "") {
      return acc;
    }
    if (isNumeric_no_whitespaces(current)) {
      return acc[Number(current)];
    }
    return acc?.[current] ?? (path.at(-1) === "length" ? 0 : "");
  }, item as { [key: string]: any });
  return parsedCode;
};

const getDescription = (
  item: Document,
  t: TFunction,
  getLocalizedStringFn: (stringOrObject?: any) => string | undefined | null,
  descPath?: string
) => {
  if (descPath?.startsWith("$.")) {
    const desc = getValue(item, descPath);
    switch (descPath.split(".").pop()) {
      case "presentationType":
        return t(`presentationType.${typeof desc === "string" ? desc : "none"}`);
      case "deviceType":
        return t(`deviceType.${typeof desc === "string" ? desc : "none"}`);
      default:
        return desc;
    }
  } else if (descPath) {
    console.log({ descPath });
    const sanitizedString = descPath
      // remove code block ${...} or  £{...} if it contains anything else than alphanumerics, [] or .
      .replace(/([$£])\{(?![\w[\].]*}).*?}/g, "");

    const regexCodePart = /\$\{(.*?)\}/g;
    const regexTranslationPart = /£\{(.*?)\}/g;
    const translationPart = sanitizedString.match(regexTranslationPart);
    const codePart = sanitizedString.match(regexCodePart);
    const template = sanitizedString.replaceAll(regexCodePart, "@$c").replaceAll(regexTranslationPart, "@$t");
    const parsedCodePart = codePart?.map((el) => {
      const parsedCodeElem = parseCodeStringFromDescription(el, item);
      if (isObject(parsedCodeElem)) {
        return getLocalizedStringFn(parsedCodeElem);
      }
      return parsedCodeElem;
    });
    const parsedTranslationPart = translationPart?.map((el) => t(el.replaceAll(/(£\{)|(\})/g, "")));
    let descriptionText = template;
    parsedCodePart?.forEach((el) => {
      descriptionText = descriptionText.replace(/@\$c/, `${el}`);
    });
    parsedTranslationPart?.forEach((el) => {
      descriptionText = descriptionText.replace(/@\$t/, `${el}`);
    });
    return descriptionText;
  }
};

const sortOptions: Array<SortOptions> = [
  { prop: "updated_at", direction: "asc", label: "components.list.sort.byUpdatedDateAsc" },
  { prop: "updated_at", direction: "desc", label: "components.list.sort.byUpdatedDateDesc" },
  { prop: "internal_title", direction: "asc", label: "components.list.sort.byInternalTitleAsc" },
  { prop: "internal_title", direction: "desc", label: "components.list.sort.byInternalTitleDesc" },
  { prop: "title", direction: "asc", label: "components.list.sort.byAudienceTitleAsc" },
  { prop: "title", direction: "desc", label: "components.list.sort.byAudienceTitleDesc" },
];

export const sortOptionsDeleted: Array<SortOptions> = [
  { prop: "deleted_at", direction: "asc", label: "components.list.sort.byDeletedDateAsc" },
  { prop: "deleted_at", direction: "desc", label: "components.list.sort.byDeletedDateDesc" },
  { prop: "internal_title", direction: "asc", label: "components.list.sort.byInternalTitleAsc" },
  { prop: "internal_title", direction: "desc", label: "components.list.sort.byInternalTitleDesc" },
  { prop: "title", direction: "asc", label: "components.list.sort.byAudienceTitleAsc" },
  { prop: "title", direction: "desc", label: "components.list.sort.byAudienceTitleDesc" },
];

const filterOptions: SelectOption[] = [
  { label: "components.list.filter.noFilter", value: "no" },
  { label: "components.list.filter.createdByMe", value: "created_by" },
  { label: "components.list.filter.updatedByMe", value: "updated_by" },
  { label: "components.list.filter.statusPublished", value: DocumentStatus.PUBLISHED },
  { label: "components.list.filter.statusDraft", value: DocumentStatus.DRAFT },
  { label: "components.list.filter.recentlyDeleted", value: "is_deleted" },
];

export const filterOptionsDeleted: SelectOption[] = [
  { label: "components.list.filter.noFilter", value: "no" },
  { label: "components.list.filter.deletedByMe", value: "deleted_by" },
];

const getDocuments =
  (
    application_instance_id: ApplicationInstance["id"],
    schema_id?: number,
    search?: string,
    sort_by_title?: boolean,
    sort_by_internal_title?: boolean,
    sort?: string,
    order_asc?: boolean,
    created_by?: number,
    updated_by?: number,
    deleted_by?: number,
    is_deleted?: boolean,
    status?: DocumentStatus,
    locale?: string,
    include_deleted?: boolean
  ): DataListProps<Document>["getItems"] =>
  async (page, page_size) =>
    Api.getAllDocuments({
      page,
      page_size,
      schema_ids: schema_id,
      application_instance_id,
      created_by,
      updated_by,
      deleted_by,
      is_deleted,
      status,
      sort_by_title,
      sort_by_internal_title,
      sort,
      order_asc,
      locale,
      search,
      include_deleted,
    }).fetchDirect({ page, page_size, count: 0, items: [], total_count: 0 });

export const DocumentView: FC<DocumentFinderProps> = ({
  listTitle,
  instance,
  langCode,
  userId,
  schemaId,
  singleDocument,
  getDeleted,
  appMenus,
  customBatchButtons,
}) => {
  const { t } = useTranslation("common");
  const { t: t_db } = useTranslation("db");
  const t_dynamic = (key: string) => (key.startsWith("db.") ? t_db(key.substring(3)) : t(key));
  const { pathname, search } = useLocation();
  const history = useNavigate();
  const getLocalizedString = resolvePotentiallyLocalizedString(langCode);

  const { setSearchParameters, sortBy, sortAscending, filterBy, initialSortOption } = useSortAndFilterState({
    sortOptions,
  });

  const [lastFetchedTimestamp, setLastFetchedTimestamp] = useState(Date.now());

  const [schemas, setSchemas] = useAsyncSafeState<Schema[] | null>(null);
  const { isLoading, startLoading, stopLoading } = useLoadingState();

  const getStatus: DocumentStatus | undefined = Object.values(DocumentStatus).some((v) => v === filterBy)
    ? (filterBy as DocumentStatus)
    : undefined;

  const [searchInput, setSearchInput] = useState<string>("");
  const [searchTerms, setSearchTerms] = useState<string>("");

  const debouncedSetSearchTerms = useDebounce<string>(500, (t) => {
    setSearchTerms(t || "");
    setLastFetchedTimestamp(Date.now());
  });

  useEffect(() => {
    if (searchInput.length !== 1 && searchInput !== searchTerms) debouncedSetSearchTerms(searchInput);
  }, [debouncedSetSearchTerms, searchInput, searchTerms]);

  useEffect(() => setLastFetchedTimestamp(Date.now), [langCode]);

  // TODO rewrite this to use memo?!
  const documentMapper = (item: Document): DataListRow => {
    if (schemaId) {
      const params = getQueryParams<FinderConfigurationQueryParams>(defaultFinderQueryParamsValues);
      return {
        key: String(getValue(item, params.key)),
        title: item.internal_title || getLocalizedString(item.title) || "*",
        subTitle: schemas?.[0].description_field_override
          ? getDescription(item, t_dynamic, getLocalizedString, schemas?.[0].description_field_override)
          : getLocalizedString(item.description),
        infoText: item.status ? `${item.id} - ${t(`status.${item.status}`)}` : String(item.id),
        chipContent: getValue(item, params.chipContent),
        imageURL: item.media_data?.thumbnail_url,
        updatedAt: item.updated_at,
        updatedBy: item.updated_by,
      };
    }

    const schemaName = getSchemaName(item, getLocalizedString, appMenus);

    return {
      key: String(item.id),
      title: item.internal_title || getLocalizedString(item.title) || "*",
      subTitle: schemas?.[0].description_field_override
        ? getDescription(item, t_dynamic, getLocalizedString, schemas?.[0].description_field_override)
        : getLocalizedString(item.description),
      infoText: schemaName ? `${item.id} - ${schemaName}` : String(item.id),
      imageURL: item.media_data?.thumbnail_url || undefined,
      updatedAt: item.deleted_at,
      updatedBy: item.updated_by,
    };
  };

  const deleteItem = async (item: Document) => {
    await Api.deleteDocument(item.id!).fetch();
  };

  const undeleteItem = async (item: Document) => {
    await Api.undeleteDocument(item.id!).fetch();
  };

  const getItems = getDocuments(
    instance.id,
    schemaId,
    searchTerms,
    sortBy === "title",
    sortBy === "internal_title",
    ["internal_title", "title"].includes(sortBy) ? undefined : `$${sortBy}`,
    sortAscending,
    filterBy === "created_by" ? userId : undefined,
    filterBy === "updated_by" ? userId : undefined,
    filterBy === "deleted_by" ? userId : undefined,
    filterBy === "is_deleted" || getDeleted,
    getStatus,
    langCode,
    !schemaId && getDeleted
  );

  const onItemClick = (item: Document) => {
    history(`${pathname}/${item.id}`, { state: { search } });
  };

  const handleOnItemsChanged = async (sortProp?: string, sortDirection?: string, filter?: string) => {
    setSearchParameters(sortProp, sortDirection, filter);
    setLastFetchedTimestamp(Date.now());
  };

  useEffect(() => {
    if (schemaId) {
      startLoading();
      const getSchema = Api.getOneSchema(schemaId);
      getSchema
        .fetchDirect(null)
        .then((schema) => setSchemas(schema ? [schema] : null))
        .finally(stopLoading);
      return getSchema.abort;
    } else if (appMenus) {
      // get schemas for mapping
      const schemaIds: number[] = [];
      appMenus.forEach((menu) => {
        menu.menu_items?.forEach((item) => {
          const schemaId = Number(item.action.schema_id);
          if (schemaId) schemaIds.push(schemaId);
        });
      });
      if (schemaIds.length) {
        const [allSchemas, cancel] = cancellablePromise(
          Promise.all(schemaIds.map((id) => Api.getOneSchema(id).fetch()))
        );
        allSchemas
          .then((schemasResponse) =>
            schemasResponse.map(([schema, error]) => {
              if (error) {
                console.error(error);
                return null;
              }
              return schema;
            })
          )
          .then((maybeNull) => maybeNull.filter(filterNull))
          .then(setSchemas);
        setLastFetchedTimestamp(Date.now());
        return cancel;
      }
    }
  }, [schemaId, appMenus, startLoading, stopLoading, setSchemas]);

  const resetPageDeps = useMemo(() => [schemaId, searchTerms], [schemaId, searchTerms]);

  if (isLoading) {
    return <Loader loadingText={t("views.cms.loadingContent")} />;
  }

  return (
    <>
      <DataList
        selectedLanguage={langCode}
        listTitle={listTitle}
        mapperFn={documentMapper}
        getItems={getItems}
        onItemClick={onItemClick}
        onDeleteItem={!getDeleted ? deleteItem : undefined}
        handleOnItemsChanged={handleOnItemsChanged}
        // Deleting an item again will delete it "forever" (not really, but for the user it seems like it)
        onDeleteItemForever={!getDeleted ? deleteItem : undefined}
        onUndeleteItem={undeleteItem}
        externalDataChanged={lastFetchedTimestamp}
        initialSortOption={initialSortOption}
        sortOptions={getDeleted ? sortOptionsDeleted : sortOptions}
        filterOptions={(getDeleted ? filterOptionsDeleted : filterOptions).map(({ label, value }) => ({
          label: t(label),
          value,
        }))}
        filter={filterBy}
        createNewButton={
          !getDeleted
            ? ({
                onCreateNew: () => history(`${pathname}/create${schemaId ? search : ""}`),
                buttonLabel: t("generic.createNew"),
                singleDocument,
              } as CreateNewButton)
            : undefined
        }
        resetPageDeps={resetPageDeps}
        searchProp={
          {
            query: searchInput,
            updateQuery: setSearchInput,
            placeholder: t("search.inputLabel"),
          } as SearchProp
        }
        onTagClicked={setSearchInput}
        customBatchButtons={customBatchButtons}
      />
    </>
  );
};

export default DocumentView;
