import { FormHelperText, FormLabel, Slider } from "@mui/material";
import type { WidgetProps } from "@rjsf/core";
import type { FocusEvent } from "react";
import { useState } from "react";
import { getValueFromUiSchema } from "@/utils/schema";
import { useDebounce } from "@/hooks/useDebounce";

const KioRangeWidget = ({
  value,
  readonly,
  disabled,
  onBlur,
  onFocus,
  schema,
  uiSchema,
  onChange,
  required,
  label,
  id,
}: WidgetProps) => {
  const [internalValue, setInternalValue] = useState(value ?? Number(schema.default) ?? 0);
  const title = getValueFromUiSchema("title", uiSchema) || schema.title || label;

  const debounceOnChange = useDebounce(400, (value) => onChange(value));
  const _onChange = (_: any, value: number | number[]) => {
    setInternalValue(value);
    debounceOnChange(value);
  };
  const _onBlur = ({ target: { value } }: FocusEvent<HTMLInputElement>) => onBlur(id, value);
  const _onFocus = ({ target: { value } }: FocusEvent<HTMLInputElement>) => onFocus(id, value);
  const helperText = getValueFromUiSchema("help", uiSchema);

  return (
    <div style={{ padding: "0 8px" }}>
      <FormLabel required={required} id={id}>
        {title}
      </FormLabel>
      <Slider
        value={internalValue}
        disabled={disabled || readonly}
        onChange={_onChange}
        onBlur={_onBlur}
        onFocus={_onFocus}
        valueLabelDisplay="auto"
        id={id}
        aria-label={label}
        min={schema.minimum}
        max={schema.maximum}
        step={schema.multipleOf}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </div>
  );
};

export default KioRangeWidget;
