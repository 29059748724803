import type { ArrayFieldTemplateProps } from "@rjsf/core";
import type { FC } from "react";
import BlocksArrayTemplate from "./BlocksArrayTemplate";
import DefaultArrayFieldTemplate from "./DefaultArrayFieldTemplate";
import { ArrayFieldLayoutTemplate } from "@/declarations/TemplateLayout";
import type { TemplateLayout } from "@/declarations/TemplateLayout";
import { getSchemaTitle } from "@/utils/schema";

// TitleField & DescriptionField is never used, and messed up typing in ArrayRelationField
export interface CustomArrayFieldTemplateProps
  extends Omit<ArrayFieldTemplateProps, "TitleField" | "DescriptionField"> {
  onChange?: (value: any) => void;
}

const ArrayFieldTemplate: FC<CustomArrayFieldTemplateProps> = (props) => {
  const layout: TemplateLayout = props.uiSchema?.["ui:layout"] || {};
  const template = layout.template || "";

  const title = getSchemaTitle(props.formContext, props.schema, props.uiSchema, props.formData) || props.title;

  if (template === ArrayFieldLayoutTemplate.BLOCKS) {
    return <BlocksArrayTemplate {...props} title={title} />;
  } else if (template === ArrayFieldLayoutTemplate.FLAT) {
    return <DefaultArrayFieldTemplate {...props} collapsibleParent={false} title={title} />;
  } else if (!!template) {
    console.warn(`(${title}) Trying to access a uiSchema template (ArrayFieldTemplate) that doesnt exist: ${template}`);
  }

  return <DefaultArrayFieldTemplate {...props} title={title} />;
};

export default ArrayFieldTemplate;
