import type { FC } from "react";
import type { FieldProps } from "@rjsf/core";
import LocationPicker from "@/components/LocationPicker";
import KioTitle from "@/framework/KioForm/common/KioTitle";
import { getValueFromUiSchema } from "@/utils/schema";

const LocationPickerField: FC<FieldProps> = ({ formData, onChange, uiSchema }) => {
  const title = getValueFromUiSchema("title", uiSchema);
  const description = getValueFromUiSchema("description", uiSchema);

  return (
    <>
      <KioTitle title={title} description={description} level={2} />
      <LocationPicker location={formData} onChange={onChange} />
    </>
  );
};

export default LocationPickerField;
