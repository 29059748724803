import type { ChangeEventHandler, FC } from "react";
import { memo, useEffect, useState } from "react";
import type { Status } from "@googlemaps/react-wrapper";
import { Wrapper } from "@googlemaps/react-wrapper";
import { RemoveCircleOutline } from "@mui/icons-material";
import { styled } from "@mui/material";
import { Map } from "@/components/GoogleMaps/Map";
import { Places } from "@/components/GoogleMaps/Places";
import type { Location } from "@/declarations/models/Location";
import Settings from "@/Settings";

export interface LocationPickerProps {
  location: Location;
  onChange?: (location: Location) => void;
}
const Remove = memo(styled(RemoveCircleOutline)`
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  background: white;
  border-radius: 50%;
  cursor: pointer;

  svg {
    height: 16px;
    width: 16px;
  }
`);

const Container = styled("div")`
  display: flex;
`;

const MapContainer = styled("div")`
  height: 300px;
  width: 300px;
  position: relative;
`;

const render = (status: Status) => <h1>{status}</h1>;

const LocationPicker: FC<LocationPickerProps> = ({ location, onChange }) => {
  const [map, setMap] = useState<google.maps.Map>();
  const [geoCoder, setGeoCoder] = useState<google.maps.Geocoder>();
  const [clickLocation, setClickLocation] = useState<string | null>();
  const [zoom, setZoom] = useState(9);
  const [center, setCenter] = useState<google.maps.LatLngLiteral>(Settings.LOCATION_PICKER_DEFAULT_COORDS);

  useEffect(() => {
    if (typeof location?.lng === "number" && typeof location?.lat === "number") {
      setCenter({
        lat: location.lat,
        lng: location.lng,
      });
    }
  }, [location]);

  const onClick = (e: google.maps.MapMouseEvent) => {
    const coords = e.latLng;
    if (!coords) return;
    geoCoder
      ?.geocode({
        location: coords,
      })
      .then((response) => {
        const filtered = response.results.filter((val) => val.types.includes("postal_town"));
        if (filtered.length > 0) {
          setClickLocation(filtered[0].formatted_address);
        }
      });
    onChange?.({ lat: coords.lat(), lng: coords.lng() });
  };

  const onIdle = (m: google.maps.Map) => {
    setZoom(m.getZoom()!);
    setCenter(m.getCenter()!.toJSON());
  };

  const handleInputChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined = (event) => {
    const { id, value } = event?.target;
    if (!id) return;
    const numVal = Number(value);
    const newFormData = { ...location, [id]: !!value && !isNaN(numVal) ? numVal : undefined };
    onChange?.(newFormData);
  };
  const locationExists = typeof location?.lat === "number" && typeof location?.lng === "number";

  const onRemoveClickHandler = () => {
    removeLocation();
  };

  const removeLocation = () => {
    onChange?.({ ...location, lat: undefined, lng: undefined });
    setClickLocation(null);
  };

  return (
    <Container>
      <Wrapper apiKey={Settings.GOOGLE_MAPS_API_KEY} render={render} libraries={["places"]}>
        <MapContainer>
          <Map
            map={map}
            setMap={setMap}
            geoCoder={geoCoder}
            setGeoCoder={setGeoCoder}
            center={center}
            onClick={onClick}
            onIdle={onIdle}
            zoom={zoom}
            style={{ height: "100%", width: "100%" }}
            options={{
              disableDefaultUI: true,
              styles: [{ elementType: "labels", stylers: [{ visibility: "on" }] }],
            }}
          >
            {locationExists && (
              <Marker
                position={{
                  lat: location?.lat ?? 0,
                  lng: location?.lng ?? 0,
                }}
              />
            )}
          </Map>
          {locationExists && <Remove onClick={onRemoveClickHandler} />}
        </MapContainer>
        <Places
          map={map}
          location={location}
          clickLocation={clickLocation}
          handleInputChange={handleInputChange}
          onLocationChange={onChange}
        />
      </Wrapper>
    </Container>
  );
};

const Marker: FC<google.maps.MarkerOptions> = (options) => {
  const [marker, setMarker] = useState<google.maps.Marker>();

  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  return null;
};

export default LocationPicker;
