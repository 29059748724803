import styled from "@emotion/styled";
import { styled as muiStyled, Typography } from "@mui/material";
import type { JSONSchema7, JSONSchema7Type } from "json-schema";
import type { FC } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import BreadcrumbNode from "@/components/BreadcrumbNode";
import type { DataListProps } from "@/components/DataList/DataList";
import DataList from "@/components/DataList/DataList";
import type { DataListRow } from "@/components/DataList/DataListRow";
import SimpleDialog from "@/components/SimpleDialog/SimpleDialog";
import type { SimpleDialogListRow } from "@/components/SimpleDialog/SimpleDialogListRow";
import type { ErrorResponse } from "@/declarations/models/ErrorResponse";
import type KioUser from "@/declarations/models/KioUser";
import type License from "@/declarations/models/License";
import type LicenseUserRole from "@/declarations/models/LicenseUserRole";
import type Role from "@/declarations/models/Role";
import type PaginationResult from "@/declarations/PaginationResult";
import { schema } from "@/declarations/schemas/user/schema";
import { uiSchema } from "@/declarations/schemas/user/uiSchema";
import KioForm from "@/framework/KioForm/KioForm";
import Loader from "@/framework/Loader";
import { useMessenger } from "@/framework/Messenger/Messenger";
import Api from "@/services/Api";

const Container = muiStyled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  padding: theme.spacing(2),
}));

const ErrorText = styled(Typography)``;

export const SingleUserView: FC = () => {
  const { t } = useTranslation("common");
  const { user_id } = useParams<{ user_id: string }>();
  const history = useNavigate();
  const { success, error, warning } = useMessenger();
  const title = t(uiSchema["ui:title"]);
  if (title) document.title = title;

  const [user, setUser] = useState<KioUser | null>(null);
  const [responseError, setResponseError] = useState<ErrorResponse | string | null>(null);
  const [userLicenses, setUserLicenses] = useState<License[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedLicenseUserRole, setSelectedLicenseUserRole] = useState<LicenseUserRole>();
  const [lastFetchedTimestamp, setLastFetchedTimestamp] = useState(Date.now());

  const editSchema: JSONSchema7 = JSON.parse(JSON.stringify(schema));
  editSchema["properties"] = {
    ...editSchema["properties"],
    username: {
      format: "email",
      type: "string",
      readOnly: true,
    },
  };

  const handleDialogClose = async (selection: Role) => {
    const userId = parseInt(user_id as string);
    if (userId && selectedLicenseUserRole?.user_role !== selection) {
      Api.updateRoleToUsersLicense(userId, selectedLicenseUserRole?.license_id || 0, selection)
        .fetch()
        .catch((e) => console.error(e));
    }
    setDialogOpen(false);
    setLastFetchedTimestamp(Date.now);
  };

  const deleteUserFromLicense = async (userLicenseRole: LicenseUserRole) => {
    const [_, error] = await Api.removeUserFromLicense(userLicenseRole.user_id, userLicenseRole.license_id).fetch();
    if (error) {
      return Promise.reject("Unable to remove user from license");
    }
    setLastFetchedTimestamp(Date.now());
  };

  const editUserLicenseRole = (licenseUserRole: LicenseUserRole) => {
    setSelectedLicenseUserRole(licenseUserRole);
    setDialogOpen(true);
  };

  const userFormData: KioUser = {
    id: user?.id ? user.id : undefined,
    name: user?.name ? user.name : "",
    username: user?.username ? user.username : "",
    is_admin: user?.is_admin ? user.is_admin : false,
    image_url: user?.image_url ? user.image_url : undefined,
    activated: user?.activated ? user.activated : false,
    is_banned: user?.is_banned ? user.is_banned : false,
    updated_at: user?.updated_at || "",
    created_at: user?.created_at || "",
  };

  const addLicenseSchema: JSONSchema7Type = {
    type: "object",
    required: ["license_id", "role"],
    properties: {
      license_id: {
        type: "integer",
      },
      role: {
        type: "string",
        enum: roles,
        enumNames: roles.map((role) => t(`userRole.${role}`)),
      },
    },
  };

  const addLicenseUiSchema: JSONSchema7Type = {
    "ui:title": "views.singleUserView.addLicense",
    license_id: {
      "ui:title": "generic.license",
      "ui:widget": "EntityAutocompleteWidget",
      "ui:options": {
        type: "License",
        valueField: "id",
        displayField: "name",
      },
    },
    role: {
      "ui:title": "views.singleUserView.role",
    },
  };

  const addLicenseOnSubmit = (evt: any): void => {
    if (evt.role && evt.license_id) {
      if (!userLicenses.some((l) => l.id === evt.license_id)) {
        const userId: number = parseInt(user_id as string);
        const role = evt.role as Role;
        const licenceId: number = parseInt(evt.license_id);

        Api.addUserToLicense(userId, licenceId, role)
          .fetch()
          .then(() => {
            setLastFetchedTimestamp(Date.now);
          });
      } else warning("License already added");
    } else warning("Missing data");
  };

  const submitUpdatedUser = async (user: KioUser) => {
    const updatedUser: KioUser | null = await Api.updateUser(user).fetchDirect(null);
    !!updatedUser ? success("generic.saved") : error("generic.saveFailed");
  };

  const getItems: DataListProps<LicenseUserRole>["getItems"] = (page: number, pageSize: number) => {
    const ctx = Api.getAllLicenseUserRoles({ user_id: parseInt(user_id as string), page, page_size: pageSize });
    const defaultValue: PaginationResult<LicenseUserRole> = {
      page,
      page_size: pageSize,
      count: 0,
      total_count: 0,
      items: [],
    };
    return ctx.fetchDirect(defaultValue);
  };

  const mapperFn = (item: LicenseUserRole): DataListRow => ({
    key: String(item.license_id),
    title: item.license_name,
    infoText: `Id: ${item.license_id}`,
    chipContent: t(`userRole.${item.user_role}`),
  });

  const mapperFnDialog = (roleItem: Role): SimpleDialogListRow => ({
    key: roleItem,
    title: t(`userRole.${roleItem}`),
  });

  const onItemClick = (item: LicenseUserRole) => {
    history(`/admin/license/${item.license_id}`);
  };

  // Fetch user data
  useEffect(() => {
    const getUser = Api.getOneUser(parseInt(user_id as string));
    getUser.fetch().then(([res, err]) => {
      if (err) {
        setResponseError(err);
        return;
      }
      if (res) setUser(res);
    });
    return () => {
      getUser.abort();
    };
  }, [setUser, user_id]);

  useEffect(() => {
    const defaultValue: PaginationResult<Role> = { page: 0, page_size: 10000, count: 0, total_count: 0, items: [] };
    const getRoles = Api.getAllRoles();
    getRoles.fetchDirect(defaultValue).then((res) => setRoles(res.items));
    return () => getRoles.abort();
  }, [setRoles]);

  useEffect(() => {
    const getUserLicense = Api.getAllLicenses({ user_id: parseInt(user_id as string) });
    getUserLicense.fetch().then(([res, err]) => {
      if (err) {
        setResponseError(err);
        return;
      }
      if (res) setUserLicenses(res.items);
    });
    return () => getUserLicense.abort();
  }, [lastFetchedTimestamp, user_id]);

  return (
    <Container>
      <BreadcrumbNode label={`${user?.name || user_id}`} />
      {!user && !responseError ? (
        <Loader loadingText={t("views.singleUserView.loadingUser")} />
      ) : user ? (
        <>
          <KioForm formData={userFormData} schema={editSchema} uiSchema={uiSchema} onSubmit={submitUpdatedUser} />
          <KioForm schema={addLicenseSchema} uiSchema={addLicenseUiSchema} onSubmit={addLicenseOnSubmit} />
        </>
      ) : (
        <ErrorText color="error">{JSON.stringify(responseError)}</ErrorText>
      )}

      <DataList
        listTitle={t("views.singleUserView.userLicenses")}
        getItems={getItems}
        mapperFn={mapperFn}
        onItemClick={onItemClick}
        onDeleteItem={deleteUserFromLicense}
        onEditItem={editUserLicenseRole}
        disableViewTypeSelection
        externalDataChanged={lastFetchedTimestamp}
        disableSorting
      />

      <SimpleDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        data={roles}
        title={`${t("views.singleUserView.dialogBoxTitle")}: ${selectedLicenseUserRole?.license_name}`}
        selectedValue={selectedLicenseUserRole?.user_role}
        mapperFn={mapperFnDialog}
      />
    </Container>
  );
};

export default SingleUserView;
