import styled from "@emotion/styled";
import { Button, DialogActions } from "@mui/material";
import type { FieldProps } from "@rjsf/core";
import type { FC } from "react";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import placeholderImage from "@/assets/img/placeholder_image_small.svg";
import DocumentListModalContent from "@/components/DocumentSelector/DocumentListModalContent";
import { FullScreenDialog, ModalWrapper } from "@/components/MediaSelector/MediaTypeTabPanel";
import type Document from "@/declarations/models/Document";
import type DocumentRelation from "@/declarations/models/DocumentRelation";
import { useEditorStore } from "@/EditorContextProvider";
import CollapseBar from "@/framework/KioForm/common/CollapseBar";
import KioTitle from "@/framework/KioForm/common/KioTitle";
import { resolvePotentiallyLocalizedString } from "@/utils/obj";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const moveItem = (
  item: DocumentRelation,
  formData: FieldProps["formData"],
  onChange: FieldProps["onChange"],
  up = false
) => {
  if (!formData || !Array.isArray(formData)) return;
  const _tmp = [...formData];
  const idx = _tmp.findIndex((_item) => _item.document_id === item.document_id);
  if (idx < 0) return;
  const t = _tmp.splice(idx, 1)?.[0];
  if (!t) return;
  if (up) {
    _tmp.splice(idx - 1, 0, t);
    onChange?.(_tmp);
    return;
  }
  _tmp.splice(idx + 1, 0, t);
  onChange?.(_tmp);
};

const StateRelationPickerField: FC<FieldProps> = ({ uiSchema, formContext, formData, onChange }) => {
  const { t } = useTranslation("common");
  const { state: editorState, addDocumentIds } = useEditorStore();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const schemaId = uiSchema["ui:options"]?.schemaId;
  const getLocalizedString = resolvePotentiallyLocalizedString(formContext.selectedLocale);
  const title = formData?.title || "";
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(
    () => () => {
      timeoutRef.current !== null && clearTimeout(timeoutRef.current);
    },
    []
  );

  const modalOpenHandler = () => setModalIsOpen((prev) => !prev);

  useEffect(() => {
    if (Array.isArray(formData)) addDocumentIds(formData.map((item: DocumentRelation) => item.document_id));
    else if (formData && formData.hasOwnProperty("document_id")) addDocumentIds([formData.document_id]);
  }, [addDocumentIds, formData]);

  const deleteItem = useCallback(
    (item: DocumentRelation) => {
      if (!formData || !Array.isArray(formData)) return;
      onChange?.([...formData.filter((_item) => _item.document_id !== item.document_id)]);
    },
    [formData, onChange]
  );

  const getMoveFn = useCallback(
    (item: DocumentRelation, moveUp = false): (() => void) =>
      () => {
        moveItem(item, formData, onChange, moveUp);
      },
    [formData, onChange]
  );

  const isMoveDisabled = useCallback(
    (item: DocumentRelation, moveUp = false): boolean => {
      if (!formData || !Array.isArray(formData)) return false;
      const idx = formData.findIndex((_item) => _item.document_id === item.document_id);
      if (!moveUp && idx >= formData.length - 1) return true;
      return moveUp && idx <= 0;
    },
    [formData]
  );

  const onSelect = (doc: Document) => {
    if (doc.id) {
      if (!Array.isArray(formData)) {
        timeoutRef.current = setTimeout(() => {
          onChange?.([{ document_id: doc.id }]);
          timeoutRef.current = null;
        });
        addDocumentIds([doc.id]);
      } else if (!formData.some((item: DocumentRelation) => item.document_id === doc.id)) {
        timeoutRef.current = setTimeout(() => {
          onChange?.([...formData, { document_id: doc.id }]);
          timeoutRef.current = null;
        });
        addDocumentIds([doc.id]);
      }
    }
  };

  const mapper = useCallback(
    (doc: Document) => ({
      key: String(doc.id),
      title: title || doc.internal_title || getLocalizedString(doc.title) || "*",
      infoText: t(`status.${doc.status}`),
      imageURL: doc.media_data?.thumbnail_url || undefined,
      updatedAt: doc.updated_at,
      updatedBy: doc.updated_by,
    }),
    [getLocalizedString, t, title]
  );

  const selectedItems = useMemo(
    () => formData?.map((item: DocumentRelation) => item.document_id.toString()) || [],
    [formData]
  );

  return (
    <Container>
      <FullScreenDialog maxWidth={"xl"} fullWidth open={modalIsOpen} onClose={modalOpenHandler} scroll={"paper"}>
        <ModalWrapper>
          <DocumentListModalContent
            mapper={mapper}
            onSelect={onSelect}
            schemaIds={schemaId ? [Number(schemaId)] : []}
            selectedItems={selectedItems}
            selectedLanguage={formContext.selectedLocale}
          />
        </ModalWrapper>
        <DialogActions>
          <Button type="button" color="primary" variant="contained" onClick={modalOpenHandler}>
            {t("generic.close")}
          </Button>
        </DialogActions>
      </FullScreenDialog>
      <KioTitle title={title} level={2} />
      {formData?.map((item: DocumentRelation) => {
        const relation = editorState.documentRelations?.find((rel) => rel.document_id == item.document_id);

        const isFromSharedInstance =
          !!relation?.application_instance_id &&
          relation?.application_instance_id !== formContext.applicationInstanceId;
        return (
          <CollapseBar
            key={`${relation?.reference_key}-${item.document_id}`}
            enableMovable
            disableExpansion
            onDelete={() => {
              deleteItem(item);
            }}
            onMoveUp={getMoveFn(item, true)}
            onMoveDown={getMoveFn(item, false)}
            moveUpButtonDisabled={isMoveDisabled(item, true)}
            moveDownButtonDisabled={isMoveDisabled(item, false)}
            enableDelete
            title={relation?.internal_title || getLocalizedString(relation?.title) || "*"}
            titleAddon={relation?.status ? `(${t(`status.${relation.status}`)})` : undefined}
            thumbnailImageSrc={relation?.media?.thumbnail_src || placeholderImage}
            isFromSharedInstance={isFromSharedInstance}
            sharedInstanceId={relation?.application_instance_id}
            selectedLocale={formContext.selectedLocale}
          />
        );
      })}
      <Button
        variant="contained"
        color="primary"
        onClick={modalOpenHandler}
        sx={{ margin: "16px auto 0 0", textTransform: "none" }}
      >
        {t("generic.editList")}
      </Button>
    </Container>
  );
};

export default memo(StateRelationPickerField);
