import { CancelPresentation } from "@mui/icons-material";
import type { FC } from "react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import type { CustomButton } from "@/components/DataList/common/DataListOptions";
import type { DataListProps } from "@/components/DataList/DataList";
import DataList from "@/components/DataList/DataList";
import type { DataListRow, DataListRowInternal } from "@/components/DataList/DataListRow";
import type { SearchProp } from "@/components/DataList/ListHeader/DataListHeader";
import type { SortOptions } from "@/components/DataList/ListHeader/SortSelector";
import type QrCode from "@/declarations/models/QrCode";
import { useDebounce } from "@/hooks/useDebounce";
import { useSortAndFilterState } from "@/hooks/useSortAndFilterState";
import Api from "@/services/Api";
import { resolvePotentiallyLocalizedString } from "@/utils/obj";
import { addTrailingSlashIfMissing } from "@/utils/url";
import type { FinderProps } from "@/views/cms/FinderView";

const getQRCodes =
  (
    instanceId?: number,
    sort_by?: string,
    order_asc?: boolean,
    search?: string,
    include_logo?: boolean
  ): DataListProps<QrCode>["getItems"] =>
  async (page, page_size) =>
    Api.getAllQrCodes({
      page,
      page_size,
      application_instance_id: instanceId,
      sort_by,
      order_asc,
      search,
    })
      .fetchDirect({ page, page_size, count: 0, total_count: 0, items: [] })
      .then((qrCodeResponse) => {
        if (qrCodeResponse.items.length) {
          const docIds: number[] = [];
          const qrCodeImages: any[] = [];

          qrCodeResponse.items.forEach((rItems) => {
            if (rItems.context_document_id) docIds.push(rItems.context_document_id);
            if (rItems.document_id) docIds.push(rItems.document_id);
          });
          return Api.getProcessedFieldsOfDocuments({ d: docIds })
            .fetchDirect(null)
            .then((docInfo) => {
              qrCodeResponse.items = qrCodeResponse.items.map((qr_data) => ({
                ...qr_data,
                document_title: docInfo?.find((doc) => doc.document_id === qr_data.document_id)?.internal_title,
                context_document_title: docInfo?.find((doc) => doc.document_id === qr_data.context_document_id)
                  ?.internal_title,
              }));
              return qrCodeResponse;
            })
            .then((qrCodeWitchDocumentData) => {
              qrCodeWitchDocumentData.items.forEach((qrCodeItem) => {
                qrCodeImages.push(
                  Api.getQRCodeImage({
                    code: `${addTrailingSlashIfMissing(qrCodeItem.base_url, "https://example.com/")}${qrCodeItem.code}`,
                    include_logo,
                  })
                    .fetchDirect({})
                    .then((imgResponse) => URL.createObjectURL(imgResponse))
                );
              });

              return Promise.all(qrCodeImages).then((res2) => res2);
            })
            .then((resolvedQrCodeImages) => {
              qrCodeResponse.items = qrCodeResponse.items.map((resItem, idx) => ({
                img_url: resolvedQrCodeImages[idx],
                ...resItem,
              }));

              return qrCodeResponse;
            });
        }

        return qrCodeResponse;
      });

const customSortOptions: Array<SortOptions> = [
  { prop: "updated_at", direction: "asc", label: "components.list.sort.byUpdatedDateAsc" },
  { prop: "updated_at", direction: "desc", label: "components.list.sort.byUpdatedDateDesc" },
];

const cleanUpQRCodesInDocuments = async (item: QrCode) => {
  if (item.context_document_id) {
    await Api.cleanUpQRCodesInDocument(item.context_document_id);
  }
  if (item.document_id) {
    await Api.cleanUpQRCodesInDocument(item.document_id);
  }
};

export const QRCodeView: FC<FinderProps> = ({ listTitle, instance, langCode }) => {
  const { t } = useTranslation("common");
  const getLocalizedString = resolvePotentiallyLocalizedString(langCode);
  const qrCodeBaseUrl = addTrailingSlashIfMissing(instance.qr_code_base_url, "https://example.com/");
  const [includeLogoInQrCode, setIncludeLogoInQrCode] = useState(true);
  const { setSearchParameters, sortBy, sortAscending, initialSortOption } = useSortAndFilterState({
    sortOptions: customSortOptions,
  });

  const [lastFetchedTimestamp, setLastFetchedTimestamp] = useState(Date.now());

  const [searchInput, setSearchInput] = useState<string>("");
  const [searchTerms, setSearchTerms] = useState<string>("");

  const debouncedSetSearchTerms = useDebounce<string>(500, (t) => {
    setSearchTerms(t || "");
    setLastFetchedTimestamp(Date.now());
  });

  const onToggleIncludeLogo = () => {
    setIncludeLogoInQrCode((prev) => !prev);
    setLastFetchedTimestamp(Date.now());
  };

  useEffect(() => {
    if (searchInput.length !== 1 && searchInput !== searchTerms) debouncedSetSearchTerms(searchInput);
  }, [debouncedSetSearchTerms, searchInput, searchTerms]);

  const handleOnItemsChanged = async (sortProp?: string, sortDirection?: string) => {
    setSearchParameters(sortProp, sortDirection);
    setLastFetchedTimestamp(Date.now());
  };

  const deleteItem = async (item: QrCode) => {
    if (!!item.id) {
      await Api.deleteQrCode(item.id!).fetch();
      await cleanUpQRCodesInDocuments(item);
      setLastFetchedTimestamp(Date.now());
    }
  };

  const getItems = getQRCodes(instance?.id, sortBy, sortAscending, searchTerms, includeLogoInQrCode);

  const getTitle = (qr: QrCode) => {
    if (qr.document_id) {
      return qr.document_title ? getLocalizedString(qr.document_title)! : `${qr.document_id}*`;
    }
    return qr.context_document_id ? t("generic.none") : t("generic.notInUse");
  };

  const getSubTitle = (qr: QrCode) => {
    if (qr.context_document_id) {
      return `${t("generic.context")}: ${
        qr.context_document_title ? getLocalizedString(qr.context_document_title) : `${qr.context_document_id}*`
      }`;
    }
    return qr.document_id ? `${t("generic.context")}: ${t("generic.none")}` : undefined;
  };

  interface QRCodeWithImage extends QrCode {
    img_url?: string;
  }

  const mapperFn = (qrCode: QRCodeWithImage): DataListRow => ({
    key: String(qrCode.id),
    title: getTitle(qrCode),
    subTitle: getSubTitle(qrCode),
    chipContent: qrCode.code,
    infoText: qrCode.document_id || qrCode.context_document_id ? t("generic.inUse") : t("generic.notInUse"),
    imageURL: qrCode.img_url,
    updatedAt: qrCode.updated_at,
    updatedBy: qrCode.updated_by,
  });

  const releaseCode = async (item: QrCode) => {
    await Api.updateQrCode({
      ...item,
      document_id: null,
      context_document_id: null,
    }).fetchDirect(null);
    await cleanUpQRCodesInDocuments(item);
  };

  const handleReleaseCode = async (item: DataListRowInternal<QrCode>) => {
    await releaseCode(item.sourceItem);
    setLastFetchedTimestamp(Date.now());
  };

  const customListItemButtons: Array<CustomButton> = [
    {
      tooltip: t("components.QRCodeField.release"),
      onClick: handleReleaseCode,
      icon: <CancelPresentation />,
      displayCondition: (item: DataListRowInternal<QrCode>) =>
        !!item.sourceItem.document_id || !!item.sourceItem.context_document_id,
    },
  ];

  const resetPageDeps = useMemo(() => [searchTerms], [searchTerms]);

  return (
    <DataList
      listTitle={listTitle}
      getItems={getItems}
      mapperFn={mapperFn}
      onItemClick={(e: QRCodeWithImage) => {
        const tempAElement = document.createElement("a");
        tempAElement.href = `${e?.img_url}`;
        tempAElement.setAttribute("download", `Kulturio__QR-code__${e?.base_url}/${e.code}.png`);
        tempAElement.click();
      }}
      onDeleteItem={deleteItem}
      onToggle={onToggleIncludeLogo}
      toggleDefault={true}
      toggleLabel={"components.list.header.includeQRLogo"}
      handleOnItemsChanged={handleOnItemsChanged}
      externalDataChanged={lastFetchedTimestamp}
      initialSortOption={initialSortOption}
      sortOptions={customSortOptions}
      customListItemButtons={customListItemButtons}
      onTagClicked={(tag) => window.open(qrCodeBaseUrl + tag)}
      disableViewTypeSelection
      customBatchButtons={[
        {
          tooltip: t("components.list.header.releaseQR"),
          onClick: (item: DataListRowInternal<any>) => releaseCode(item.sourceItem),
          icon: <CancelPresentation />,
          confirmationText: t("components.list.header.confirmReleaseQR"),
        },
      ]}
      resetPageDeps={resetPageDeps}
      searchProp={
        {
          query: searchInput,
          updateQuery: setSearchInput,
          placeholder: t("search.inputLabel"),
        } as SearchProp
      }
    />
  );
};

export default QRCodeView;
