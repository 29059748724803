import type { UiSchema } from "@rjsf/core";

export const licenseUiSchema: UiSchema = {
  "ui:title": "schemaTypes.license",
  id: {
    "ui:title": "generic.id",
  },
  license_key: {
    "ui:title": "generic.license_key",
  },
  name: {
    "ui:title": "generic.name",
  },
  description: {
    "ui:title": "generic.description",
  },
  owner_id: {
    "ui:title": "generic.owner",
    "ui:widget": "EntityAutocompleteWidget",
    "ui:options": {
      type: "Owner",
      valueField: "id",
      displayField: "name",
    },
  },
  max_users: {
    "ui:title": "generic.max_users",
    "ui:colspan": 6,
  },
  expires_at: {
    "ui:title": "generic.expires_at",
    "ui:widget": "DateTimeWidget",
    "ui:colspan": 6,
  },
};
