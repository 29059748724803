import styled from "@emotion/styled";
import type { FC } from "react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import i18next from "i18next";
import type Owner from "@/declarations/models/Owner";
import { schema } from "@/declarations/schemas/owner/schema";
import { uiSchema } from "@/declarations/schemas/owner/uiSchema";
import KioForm from "@/framework/KioForm/KioForm";
import Loader from "@/framework/Loader";
import { useMessenger } from "@/framework/Messenger/Messenger";
import { useLoadingState } from "@/hooks/useLoadingState";
import { usePrompt } from "@/hooks/useUNSAFE_Prompt";
import Api from "@/services/Api";
import { deepCompareFormData, equateEmpty, getLanguageCode, resolvePotentiallyLocalizedString } from "@/utils/obj";
import Settings from "@/Settings";
import BreadcrumbNode from "@/components/BreadcrumbNode";
import EditorHeader from "@/views/cms/EditorView/EditorHeader";
import Layout from "@/framework/Layout";

const Container = styled("div")`
  padding: 16px;
`;

interface EditOwnerProps {
  isDirty: boolean;
  setIsDirty: (isDirty: boolean) => void;
}

const EditOwnerView: FC<EditOwnerProps> = ({ isDirty, setIsDirty }) => {
  const { t } = useTranslation();
  const { success, error } = useMessenger();
  const ownerId = Number(useParams<{ ownerId?: string }>().ownerId);
  const { isLoading, startLoading, stopLoading } = useLoadingState();
  const [selectedLocale, setSelectedLocale] = useState<string>(getLanguageCode(i18next.language));
  const getLocalizedString = resolvePotentiallyLocalizedString(getLanguageCode(i18next.language));
  const [formData, setFormData] = useState<Owner | null>();
  const [unmodifiedData, setUnmodifiedData] = useState<Owner | null>();
  let title: string = "";
  if (formData?.name) {
    title = getLocalizedString(formData.name) || "error: owner name not found";
    document.title = title;
  }
  const availableLocales = Settings.SUPPORTED_APP_LOCALES;
  const submitButtonRef = useRef<HTMLButtonElement | null>(null);

  usePrompt(t("generic.unsavedChangesMessage"), isDirty);

  const setCleanedData = (owner: Owner) => {
    const cleanedData = Object.fromEntries(
      Object.entries(owner).map(([k, v]) => [k, equateEmpty(v) ?? undefined])
    ) as Owner;
    setFormData(cleanedData);
    setUnmodifiedData(cleanedData);
  };

  const handleSubmit = async (owner: Owner) => {
    const updateOwner: Owner | null = await Api.updateOwnerLimited(owner).fetchDirect(null);
    !!updateOwner ? success("generic.saved") : error("generic.saveFailed");
    if (!!updateOwner) {
      setCleanedData(updateOwner);
      setIsDirty(false);
    }
  };

  const handleChange = (_formdata: any) => {
    if (!_formdata || !unmodifiedData) return;
    if (!deepCompareFormData(_formdata, unmodifiedData)) {
      setIsDirty(true);
    } else {
      setIsDirty(false);
    }
    setFormData(_formdata);
  };

  useEffect(() => {
    startLoading();
    const getOwner = Api.getOneOwner(ownerId);

    getOwner
      .fetch()
      .then(([value, e]) => {
        if (value) setCleanedData(value);
        if (e) typeof e === "string" ? error(e) : error(e.message);
      })
      .finally(stopLoading);
    return getOwner.abort;
  }, [ownerId]);

  if (isLoading) return <Loader loadingText="views.cms.loadingContent" />;

  return (
    <>
      <BreadcrumbNode label={title} absolutePath={""} />
      <Layout
        header={
          <EditorHeader
            selectedLocale={selectedLocale}
            onSubmit={() => submitButtonRef.current?.click()}
            setSelectedLocale={setSelectedLocale}
            lastModified={formData?.updated_at}
            lastModifiedBy={formData?.updated_by}
            formIsDirty={isDirty}
            modalEditor={false}
            previewOpen={false}
            onPreviewOpenChanged={() => {}}
            alternativeAvailableLocales={availableLocales}
            disablePreview
          />
        }
      >
        <Container>
          {schema && (
            <KioForm
              schema={schema}
              uiSchema={uiSchema}
              formData={formData}
              onSubmit={handleSubmit}
              onChange={handleChange}
              selectedLocale={selectedLocale}
            >
              <button ref={submitButtonRef} type="submit" hidden={true} />
            </KioForm>
          )}
        </Container>
      </Layout>
    </>
  );
};

export default EditOwnerView;
