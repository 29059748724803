import { FormLabel, Switch } from "@mui/material";

import type { WidgetProps } from "@rjsf/core";
import type { ChangeEvent, FC } from "react";
import styled from "@emotion/styled";
import { HelpText } from "@/framework/KioForm/common/HelpText";

const Row = styled.div`
  display: flex;
  align-items: center;
  min-width: fit-content;
  height: 100%;
  margin-left: -12px;
`;

export interface SwitchWidgetProps extends WidgetProps {
  value: boolean;
  trueText?: string;
  falseText?: string;
}

const SwitchWidget: FC<SwitchWidgetProps> = ({
  onChange,
  value = false,
  color = "primary",
  disabled,
  label,
  required,
  readonly,
  uiSchema,
}) => {
  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    onChange?.(e.target.checked);
  };
  const switchLabel = uiSchema["ui:label"] || uiSchema["ui:title"] || label;
  return (
    <Row>
      <Switch
        color="primary"
        onChange={onChangeHandler}
        checked={value}
        value={value}
        disabled={disabled || readonly}
        required={required}
        aria-required={required}
        aria-disabled={disabled}
        aria-readonly={readonly}
        inputProps={{ "aria-labelledby": "switchLabel" }}
      />
      <FormLabel id="switchLabel" color="primary">
        {switchLabel}
        <HelpText uiSchema={uiSchema} />
      </FormLabel>
    </Row>
  );
};

export default SwitchWidget;
