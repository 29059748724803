import type { FC } from "react";
import type Media from "@/declarations/models/Media";
import FormButtons from "@/components/FormButtons";
import DMPreview from "@/components/MediaPreview/DMPreview";
import { useLoadingState } from "@/hooks/useLoadingState";
import Api from "@/services/Api";
import Loader from "@/framework/Loader";
import { useStore } from "@/Store";

export interface EditDMMediaProps {
  media: Media;
  onCancel: () => void;
  onSubmit: () => void;
}

export const EditDMMedia: FC<EditDMMediaProps> = ({ media, onCancel, onSubmit }) => {
  const ownerId: number | null = media.owner_id || null;
  const { state } = useStore();
  const instanceId: number | null = media.application_instance_id || state.cmsContextInstance?.id || null;
  const { isLoading, startLoading, stopLoading } = useLoadingState();

  const handleSaveChanges = () => {
    startLoading();
    Api.updateMedia({
      ...media,
      owner_id: ownerId || undefined,
      application_instance_id: instanceId || undefined,
    })
      .fetchDirect(null)
      .then((saved) => {
        if (!!saved) {
          onSubmit();
        }
      })
      .finally(stopLoading);
  };

  return (
    <>
      <DMPreview url={media.thumbnail_src || media.src} name={media.name} />
      {isLoading && <Loader />}
      <FormButtons
        disableCancelButton={isLoading}
        disableSubmitButton={isLoading || (!ownerId && !instanceId)}
        onCancel={onCancel}
        onSubmit={handleSaveChanges}
      />
    </>
  );
};

export default EditDMMedia;
