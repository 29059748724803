import type { FC } from "react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import type { DataListProps } from "@/components/DataList/DataList";
import DataList from "@/components/DataList/DataList";
import type { DataListRow } from "@/components/DataList/DataListRow";
import type { SearchProp } from "@/components/DataList/ListHeader/DataListHeader";
import type { SortOptions } from "@/components/DataList/ListHeader/SortSelector";
import type { AddMediaContentProps } from "@/components/MediaSelector/AddMediaModal";
import DMTabPanel from "@/components/MediaSelector/DMTabPanel";
import AddDMSContent from "@/components/MediaSelector/forms/AddDMSContent";
import AddDocumentMedia from "@/components/MediaSelector/forms/AddDocumentMedia";
import AddModel3DMedia from "@/components/MediaSelector/forms/AddModel3DMedia";
import AddSketchfabMedia from "@/components/MediaSelector/forms/AddSketchfabMedia";
import AddVimeoMedia from "@/components/MediaSelector/forms/AddVimeoMedia";
import AddYouTubeMedia from "@/components/MediaSelector/forms/AddYouTubeMedia";
import type ApplicationInstance from "@/declarations/models/ApplicationInstance";
import type Media from "@/declarations/models/Media";
import MediaType from "@/declarations/models/MediaType";
import CollapseBar from "@/framework/KioForm/common/CollapseBar";
import type { SelectOption } from "@/framework/KioForm/common/KioSelect";
import { useDebounce } from "@/hooks/useDebounce";
import { useSortAndFilterState } from "@/hooks/useSortAndFilterState";
import Api from "@/services/Api";
import { filterOptionsDeleted, sortOptionsDeleted } from "@/views/cms/DocumentView";
import type { FinderProps } from "@/views/cms/FinderView";

interface MediaFinderProps extends FinderProps {
  mediaType?: MediaType;
}

const sortOptions: Array<SortOptions> = [
  { prop: "updated_at", direction: "asc", label: "components.list.sort.byUpdatedDateAsc" },
  { prop: "updated_at", direction: "desc", label: "components.list.sort.byUpdatedDateDesc" },
  { prop: "internal_title", direction: "asc", label: "components.list.sort.byInternalTitleAsc" },
  { prop: "internal_title", direction: "desc", label: "components.list.sort.byInternalTitleDesc" },
  { prop: "title", direction: "asc", label: "components.list.sort.byAudienceTitleAsc" },
  { prop: "title", direction: "desc", label: "components.list.sort.byAudienceTitleDesc" },
];

const filterOptions: SelectOption[] = [
  { label: "components.list.filter.noFilter", value: "no" },
  { label: "components.list.filter.createdByMe", value: "created_by" },
  { label: "components.list.filter.updatedByMe", value: "updated_by" },
];

const getMedia =
  (
    application_instance_id: ApplicationInstance["id"],
    media_type?: MediaType,
    search?: string,
    order_by?: string,
    order_asc?: boolean,
    created_by?: number,
    updated_by?: number,
    deleted_by?: number,
    sort_by_title?: boolean,
    locale?: string,
    is_deleted?: boolean,
    include_deleted?: boolean
  ): DataListProps<Media>["getItems"] =>
  async (page, page_size) =>
    Api.getAllMedia({
      page,
      page_size,
      media_types: media_type,
      application_instance_id,
      search,
      created_by,
      updated_by,
      deleted_by,
      order_by,
      order_asc,
      sort_by_title,
      locale,
      is_deleted,
      include_deleted,
    }).fetchDirect({ page, page_size, count: 0, items: [], total_count: 0 });

export const MediaView: FC<MediaFinderProps> = ({ listTitle, instance, langCode, userId, mediaType, getDeleted }) => {
  const { t } = useTranslation("common");
  const { pathname } = useLocation();
  const history = useNavigate();

  const { setSearchParameters, sortBy, sortAscending, filterBy, initialSortOption } = useSortAndFilterState({
    sortOptions,
  });
  const [lastFetchedTimestamp, setLastFetchedTimestamp] = useState(Date.now());

  const [uploadExpanded, setUploadExpanded] = useState<boolean>(false);

  const [searchInput, setSearchInput] = useState<string>("");
  const [searchTerms, setSearchTerms] = useState<string>("");

  const debouncedSetSearchTerms = useDebounce<string>(500, (t) => {
    setSearchTerms(t || "");
    setLastFetchedTimestamp(Date.now());
  });

  useEffect(() => {
    if (searchInput.length !== 1 && searchInput !== searchTerms) debouncedSetSearchTerms(searchInput);
  }, [debouncedSetSearchTerms, searchInput, searchTerms]);

  useEffect(() => setLastFetchedTimestamp(Date.now), [mediaType, langCode]);

  const mediaMapper = (item: Media): DataListRow => ({
    key: String(item.id),
    title: item.name || item.title || "*",
    subTitle: item.name ? item.title : "",
    infoText: t(`mediaType.${item.media_type}`),
    chipContent: !getDeleted ? item.tags : undefined,
    updatedAt: !getDeleted ? item.updated_at : item.deleted_at,
    updatedBy: !getDeleted ? item.updated_by : item.deleted_by,
    imageURL: item.thumbnail_override?.mediaId || item.thumbnail_src,
  });

  const deleteItem = async (item: Media) => {
    await Api.deleteMedia(item.id!).fetch();
  };

  const undeleteItem = async (item: Media) => {
    await Api.undeleteMedia(item.id!).fetch();
  };

  const getItems = getMedia(
    instance.id,
    mediaType,
    searchTerms,
    ["internal_title", "title"].includes(sortBy) ? "name" : sortBy,
    sortAscending,
    filterBy === "created_by" ? userId : undefined,
    filterBy === "updated_by" ? userId : undefined,
    filterBy === "deleted_by" ? userId : undefined,
    sortBy === "title",
    langCode,
    getDeleted,
    !mediaType && getDeleted
  );

  const onItemClick = (item: Media) => {
    item.media_type === MediaType.DM
      ? window.open(`https://digitaltmuseum.org/${item.identifier}`)
      : history(`${pathname}/media/${item.id}`);
  };

  const handleOnItemsChanged = async (sortProp?: string, sortDirection?: string, filter?: string) => {
    setSearchParameters(sortProp, sortDirection, filter);
    setLastFetchedTimestamp(Date.now());
  };

  const contentComponentProps = {
    onMediaSaved: () => setLastFetchedTimestamp(Date.now()),
    instanceId: instance.id,
    ownerId: instance.owner_id,
  } as AddMediaContentProps;

  const resetPageDeps = useMemo(() => [mediaType, searchTerms], [mediaType, searchTerms]);

  return (
    <>
      {mediaType && !getDeleted && (
        <CollapseBar
          title={t(`components.MediaSelector.createContent.${mediaType}`)}
          expanded={uploadExpanded}
          setExpanded={setUploadExpanded}
        >
          {mediaType === MediaType.IMAGE || mediaType === MediaType.VIDEO || mediaType === MediaType.AUDIO ? (
            <AddDMSContent allowedMediaTypes={[mediaType]} isModal={false} {...contentComponentProps} />
          ) : (
            (mediaType === MediaType.DOCUMENT && <AddDocumentMedia {...contentComponentProps} />) ||
            (mediaType === MediaType.YOUTUBE && <AddYouTubeMedia {...contentComponentProps} />) ||
            (mediaType === MediaType.VIMEO && <AddVimeoMedia {...contentComponentProps} />) ||
            (mediaType === MediaType.SKETCHFAB && <AddSketchfabMedia {...contentComponentProps} />) ||
            (mediaType === MediaType.MODEL_3D && <AddModel3DMedia {...contentComponentProps} />) ||
            (mediaType === MediaType.DM && (
              <DMTabPanel
                onSelect={() => setLastFetchedTimestamp(Date.now())}
                instanceId={instance.id}
                ownerId={instance.owner_id}
              />
            ))
          )}
        </CollapseBar>
      )}

      <DataList
        selectedLanguage={langCode}
        listTitle={listTitle}
        mapperFn={mediaMapper}
        getItems={getItems}
        onItemClick={onItemClick}
        onDeleteItem={!getDeleted ? deleteItem : undefined}
        handleOnItemsChanged={handleOnItemsChanged}
        // Deleting an item again will delete it "forever" (not really, but for the user it seems like it)
        onDeleteItemForever={!getDeleted ? deleteItem : undefined}
        onUndeleteItem={undeleteItem}
        externalDataChanged={lastFetchedTimestamp}
        initialSortOption={initialSortOption}
        sortOptions={getDeleted ? sortOptionsDeleted : sortOptions}
        filterOptions={(getDeleted ? filterOptionsDeleted : filterOptions).map(({ label, value }) => ({
          label: t(label),
          value,
        }))}
        filter={filterBy}
        resetPageDeps={resetPageDeps}
        searchProp={
          {
            query: searchInput,
            updateQuery: setSearchInput,
            placeholder: t("search.inputLabel"),
          } as SearchProp
        }
        onTagClicked={setSearchInput}
      />
    </>
  );
};

export default MediaView;
