import type { JSONSchema7 } from "json-schema";

export const schema: JSONSchema7 = {
  type: "object",
  required: ["name", "application_id"],
  properties: {
    id: {
      type: "number",
      readOnly: true,
    },
    key: {
      type: "string",
      readOnly: true,
    },
    name: {
      type: "string",
      minLength: 1,
    },
    description: {
      type: "string",
    },
    application_id: {
      type: "integer",
    },
  },
};
