import type { DragEvent, FC } from "react";
import { useRef } from "react";
import type { FieldProps } from "@rjsf/core";
import { utils } from "@rjsf/core";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import type { JSONSchema7 } from "json-schema";
import MediaSelectorField from "@/framework/KioForm/fields/MediaSelectorField";
import { calculateCoordinates } from "@/utils/geometry";

const ImageMapField: FC<FieldProps> = ({
  schema,
  uiSchema,
  formContext,
  formData,
  onChange,
  required,
  disabled,
  readonly,
  idSchema,
  errorSchema,
  onBlur,
  onFocus,
  registry,
  autofocus,
}) => {
  const { t } = useTranslation("common");

  // HACK: same hack/workaround as in DocumentRelationField.
  // When the SchemaFields onChange is called, formData somehow contains the default value
  // for background, removing selected mediaId.
  const backgroundImageRef = useRef<any>(formData?.["background"]);

  const _schema = utils.retrieveSchema(schema, registry.definitions, formData);

  const backgroundImageFormData = { ...formData?.["background"] };
  const markersFormData: any[] = formData?.["markers"] || [];

  const backgroundImageSchema = _schema.properties?.background
    ? utils.retrieveSchema(_schema.properties?.background, registry.definitions, backgroundImageFormData)
    : null;
  const markersSchema = _schema.properties?.markers
    ? utils.retrieveSchema(_schema.properties?.markers, registry.definitions, markersFormData)
    : null;

  const errorPrefix = "kioForm.fields.ImageMapField.errors";

  function* schemaErrors(): IterableIterator<string> {
    if (backgroundImageSchema == null) {
      yield `${errorPrefix}.missingBackgroundSchema`;
    }
    if (markersSchema == null) {
      yield `${errorPrefix}.missingMarkersSchema`;
    } else {
      if (markersSchema.type !== "array") {
        yield `${errorPrefix}.notArray`;
      }
      // @ts-ignore
      const resolvedItemSchema = utils.retrieveSchema(markersSchema.items, registry.definitions, markersFormData);
      if (utils.getSchemaType(resolvedItemSchema) !== "object") {
        yield `${errorPrefix}.itemsNotObject`;
      }
      if (!resolvedItemSchema.properties?.["coordinates"]) {
        yield `${errorPrefix}.noCoordinates`;
      }
    }
  }

  const errors = Array.from(schemaErrors());
  if (errors.length) {
    return (
      <>
        {errors.map((e) => (
          <Typography key={e} color="error">
            {t(e)}
          </Typography>
        ))}
      </>
    );
  }

  const defaultMarkersUISchema = {
    items: {
      coordinates: {
        x: {
          "ui:widget": "range",
        },
        y: {
          "ui:widget": "range",
        },
      },
    },
  };
  const markersUISchema = utils.mergeObjects(defaultMarkersUISchema, uiSchema?.["markers"]);

  const handleImageChange = (imageFormData: any) => {
    // See above "HACK:"
    backgroundImageRef.current = imageFormData;
    onChange({
      ...formData,
      background: imageFormData,
    });
  };

  function onMarkersUpdate(markers: any[]) {
    onChange({
      ...formData,
      // see above "HACK:"
      background: backgroundImageRef.current,
      markers,
    });
  }

  function handleMarkerDrop(i: number, e: DragEvent<HTMLElement>) {
    const newCoordinates = calculateCoordinates(e);

    const newMarker = {
      ...markersFormData[i],
      coordinates: newCoordinates,
    };

    onChange({
      ...formData,
      markers: [...markersFormData.slice(0, i), newMarker, ...markersFormData.slice(i + 1)],
    });
  }

  const { SchemaField } = registry.fields;

  return (
    <>
      <MediaSelectorField
        schema={backgroundImageSchema as JSONSchema7}
        uiSchema={uiSchema["background"]}
        idSchema={idSchema["background"]}
        formData={formData?.["background"]}
        errorSchema={errorSchema?.["background"]}
        onChange={handleImageChange}
        onBlur={onBlur}
        onFocus={onFocus}
        registry={registry}
        formContext={formContext}
        autofocus={autofocus}
        disabled={disabled}
        readonly={readonly}
        required={required}
        name={`background`}
        markers={markersFormData.map((m, i) => ({
          x: m.coordinates.x ?? 50,
          y: m.coordinates.y ?? 50,
          index: i,
        }))}
        onMarkerDrop={handleMarkerDrop}
      />
      <SchemaField
        formData={markersFormData}
        schema={markersSchema as JSONSchema7}
        registry={registry}
        formContext={formContext}
        onChange={onMarkersUpdate}
        idSchema={idSchema["markers"]}
        uiSchema={markersUISchema}
        errorSchema={errorSchema?.["markers"]}
        autofocus={autofocus}
        disabled={disabled}
        readonly={readonly}
        required={required}
        onBlur={onBlur}
        onFocus={onFocus}
        name={"markers"}
      />
    </>
  );
};

export default ImageMapField;
