import type { JSONSchema7 } from "json-schema";

export const licenseSchema: JSONSchema7 = {
  type: "object",
  title: "License",
  properties: {
    id: {
      type: "integer",
      readOnly: true,
    },
    license_key: {
      type: "string",
      readOnly: true,
    },
    name: {
      type: "string",
      minLength: 1,
    },
    description: {
      type: ["string", "null"],
    },
    owner_id: {
      type: "integer",
    },
    max_users: {
      type: ["integer", "null"],
      minimum: 0,
    },
    expires_at: {
      type: ["string", "null"],
      format: "date-time",
    },
  },
  required: ["name", "owner_id"],
};
