import { Add, List } from "@mui/icons-material";
import { Button, DialogActions, Menu as MuiMenu, MenuItem, styled } from "@mui/material";
import type { TFunction } from "i18next";
import type { FC, MouseEvent } from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import type { DataListRow } from "@/components/DataList/DataListRow";
import DocumentListModalContent from "@/components/DocumentSelector/DocumentListModalContent";
import { FullScreenDialog, ModalWrapper } from "@/components/MediaSelector/MediaTypeTabPanel";
import type Document from "@/declarations/models/Document";
import type Menu from "@/declarations/models/Menu";
import type Schema from "@/declarations/models/Schema";
import { useEditorStore } from "@/EditorContextProvider";
import CollapseBar from "@/framework/KioForm/common/CollapseBar";
import type { RelationFieldOptions } from "@/framework/KioForm/fields/DocumentRelationField";
import Loader from "@/framework/Loader";
import Api from "@/services/Api";
import Settings from "@/Settings";
import { useStore } from "@/Store";
import { cancellablePromise } from "@/utils/async";
import { getValue, resolvePotentiallyLocalizedString } from "@/utils/obj";
import EditDocumentLoader from "@/views/cms/EditorView/EditDocumentLoader";

interface DocumentSelectorProps {
  value?: number;
  onSelect: (document_id: number) => void;
  selectedLanguage: string;
  onTitleChange?: (t?: string | null) => void;
  multiple: boolean;
  selectedItems?: string[];
  uiOptions?: RelationFieldOptions;
}

export const getSchemaName = (doc: Document, getLocalizedString: (str: any) => any, appMenus?: Menu[]) => {
  let schemaTitle: string | null | undefined;
  if (appMenus?.length) {
    appMenus.some((menu) =>
      menu.menu_items?.some((item) => {
        if (Number(item.action.schema_id) === doc.schema_id) {
          schemaTitle = getLocalizedString(item.title);
          return true;
        }
        return false;
      })
    );
  }
  if (schemaTitle) return schemaTitle;
};

function createDataListMapper(
  schemas: Schema[],
  getLocalizedString: (str: any) => any,
  appMenus: Menu[],
  t: TFunction
): (doc: Document) => DataListRow {
  const fieldsBySchemaId = schemas.reduce(
    (acc, v) =>
      acc.set(v.id!, {
        schemaName: v.name,
        description_field_override: v.description_field_override,
      }),
    new Map<
      number,
      Pick<
        Schema,
        | "internal_title_field_json_path"
        | "title_field_json_path"
        | "image_field_json_path"
        | "description_field_json_path"
        | "description_field_override"
      > & { schemaName: string }
    >()
  );
  return function mapper(doc: Document) {
    const fieldsForThisSchema = fieldsBySchemaId.get(doc.schema_id);
    return {
      key: String(doc.id),
      title: doc.internal_title || getLocalizedString(doc.title) || "*",
      subTitle: getSchemaName(doc, getLocalizedString, appMenus),
      infoText: doc.status ? `${doc.id} - ${t(`status.${doc.status}`)}` : String(doc.id),
      chipContent: getValue(doc, "content.tags.internal"),
      imageURL: doc.media_data?.thumbnail_url || undefined,
      updatedAt: doc.updated_at,
      updatedBy: doc.updated_by,
    };
  };
}

const ButtonRow = styled("div")(({ theme }) => ({
  display: "flex",
  flexFlow: "row nowrap",
  gap: theme.spacing(2),
  marginTop: theme.spacing(2),
  width: "100%",
  "& .MuiButton-label": {
    textTransform: "none",
    fontWeight: 700,
  },
}));

export function filterNull<T>(a: T | undefined | null): a is T {
  return a != null;
}

const DocumentSelector: FC<DocumentSelectorProps> = ({
  onSelect,
  selectedLanguage = Settings.DEFAULT_LOCALE,
  onTitleChange,
  multiple,
  selectedItems,
  uiOptions,
}) => {
  const { t } = useTranslation("common");
  const { state } = useStore();
  const { addDocumentIds } = useEditorStore();
  const applicationId = state.cmsContextInstance?.application_id;

  const [showModal, setShowModal] = useState(false);
  const [mode, setMode] = useState<"find" | "create">("find");
  const [selectedSchemaId, setSelectedSchemaId] = useState<number | undefined>(undefined);
  const [document, setDocument] = useState<Document | null>(null);
  const [schemas, setSchemas] = useState<Array<Schema>>([]);
  const docRef = useRef<Document | null>(null);
  const [anchorElement, setAnchorElement] = useState<Element | null>(null);
  const getLocalizedString = resolvePotentiallyLocalizedString(selectedLanguage);

  const addButtonLabel = uiOptions?.addButton?.["ui:label"] || t("components.relationField.findDocumentLabel");
  const createButtonLabel = uiOptions?.createButton?.["ui:label"] || t("components.relationField.createDocumentLabel");
  const schemaIds = uiOptions?.schemaIds || [];

  const [appMenus, setAppMenus] = useState<Menu[]>([]);
  const [isDirty, setIsDirty] = useState<boolean>(false);

  useEffect(() => {
    const getMenus = async () => {
      if (applicationId) {
        const getApp = Api.getOneApplication(applicationId);
        const menus = await getApp
          .fetchDirect(null)
          .then((app) => app?.menu || [])
          .finally(getApp.abort);
        if (!active) return;
        setAppMenus(menus);
      }
    };
    let active = true;
    getMenus().then(() => (active = false));
  }, [applicationId]);

  useEffect(() => {
    if (!schemaIds || !schemaIds.length) return;
    const [allSchemas, cancel] = cancellablePromise(Promise.all(schemaIds.map((id) => Api.getOneSchema(id).fetch())));
    allSchemas
      .then((schemasResponse) =>
        schemasResponse.map(([schema, error]) => {
          if (error) {
            console.error(error);
            return null;
          }
          return schema;
        })
      )
      .then((maybeNull) => maybeNull.filter(filterNull))
      .then(setSchemas);

    return cancel;
  }, [schemaIds, setSchemas]);

  useEffect(() => {
    if (!document?.id) return;
    onSelect(document.id);
    addDocumentIds([document.id]);
  }, [document]);

  const mapper = useMemo(
    () => createDataListMapper(schemas, getLocalizedString, appMenus, t),
    [schemas, selectedLanguage]
  );

  useEffect(() => {
    if (document) {
      const title = getLocalizedString(mapper(document).title);
      onTitleChange?.(title);
    } else {
      onTitleChange?.(null);
    }
    if (document === docRef.current) {
      return;
    }
  }, [mapper, onTitleChange, document, getLocalizedString]);

  const closeModal = () => {
    if (mode === "find" || !isDirty || window.confirm(t("generic.unsavedChangesMessage"))) setShowModal(false);
  };

  const openFindModal = () => {
    setMode("find");
    setShowModal(true);
  };

  const openCreateModal = (schemaId: number) => {
    setMode("create");
    setSelectedSchemaId(schemaId);
    setShowModal(true);
  };

  if (!schemas.length) return <Loader />;

  const modalContent = !showModal ? undefined : mode === "find" ? (
    <DocumentListModalContent
      mapper={mapper}
      schemaIds={schemaIds}
      onSelect={(doc) => {
        setDocument(doc);
        if (!multiple) setShowModal(false);
      }}
      selectedItems={selectedItems}
      selectedLanguage={selectedLanguage}
    />
  ) : (
    <EditDocumentLoader
      schemaId={selectedSchemaId}
      onSave={(doc) => !!doc && setDocument(doc)}
      disablePreview
      modalEditor={true}
      isDirty={isDirty}
      setIsDirty={setIsDirty}
    />
  );

  const mappedDoc = !!document ? mapper(document) : null;
  if (mappedDoc && typeof mappedDoc.title === "object") {
    if (mappedDoc.title.hasOwnProperty(selectedLanguage)) {
      mappedDoc.title = mappedDoc.title[selectedLanguage];
    } else {
      mappedDoc.title = "";
    }
  }

  const schemaMenuOpen = Boolean(anchorElement);
  const openSchemaSelect = (e: MouseEvent<HTMLButtonElement>) => {
    if (schemas?.length === 1) {
      openCreateModal(schemas[0]!.id!);
    } else {
      setAnchorElement(e.currentTarget);
    }
  };
  const onMenuClose = () => setAnchorElement(null);
  return (
    <>
      {!multiple && mappedDoc && (
        <CollapseBar title={String(mappedDoc.title)} onDelete={() => setDocument(null)} enableDelete disableExpansion />
      )}
      {(multiple || !document) && (
        <ButtonRow>
          <Button variant="outlined" color="primary" onClick={openFindModal} startIcon={<List />}>
            {addButtonLabel}
          </Button>
          <Button
            id="schema-menu-button"
            variant="outlined"
            color="primary"
            onClick={openSchemaSelect}
            startIcon={<Add />}
          >
            {createButtonLabel}
          </Button>
          <MuiMenu
            open={schemaMenuOpen}
            anchorEl={anchorElement}
            onClose={onMenuClose}
            MenuListProps={{
              "aria-labelledby": "schema-menu-button",
            }}
          >
            {schemas
              .filter((s) => s != null)
              .map((schema) => (
                <MenuItem
                  key={schema.id}
                  onClick={() => {
                    openCreateModal(schema!.id!);
                    setAnchorElement(null);
                  }}
                >
                  {schema?.name}
                </MenuItem>
              ))}
          </MuiMenu>
        </ButtonRow>
      )}
      <FullScreenDialog maxWidth={"xl"} fullWidth open={showModal} scroll={"paper"}>
        <ModalWrapper>{modalContent}</ModalWrapper>
        <DialogActions>
          <Button type="button" color="primary" variant="contained" onClick={closeModal}>
            {t("generic.close")}
          </Button>
        </DialogActions>
      </FullScreenDialog>
    </>
  );
};

export default DocumentSelector;
