import { styled } from "@mui/material";
import type { ObjectFieldTemplateProps } from "@rjsf/core";
import type { FC } from "react";
import { useEffect, useState } from "react";
import CollapseBar, { SettingsContentContainer } from "../../common/CollapseBar";
import { hasAnyErrors, splitHeaderAndBodyContent } from "@/utils/form";
import GridTemplateContainer from "@/framework/KioForm/common/GridTemplateContainer";
import type { TemplateOptions } from "@/declarations/TemplateLayout";

const Container = styled("div")({
  width: "100%",
});

const SectionObjectTemplate: FC<ObjectFieldTemplateProps> = ({
  title,
  description,
  uiSchema,
  properties,
  schema,
  formData,
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const options: TemplateOptions = uiSchema?.["ui:layout"]?.templateOptions;

  const hasFormErrors = properties.some((child) => hasAnyErrors(child?.content?.props?.errorSchema));
  let titleAddon = undefined;
  if (Array.isArray(formData)) titleAddon = `(${formData.length})`;
  else if (Array.isArray(formData.list)) titleAddon = `(${formData.list.length})`;

  useEffect(() => {
    setExpanded(!!options?.default?.expanded);
  }, [options]);

  useEffect(() => {
    if (hasFormErrors) {
      setExpanded(true);
    }
  }, [hasFormErrors]);

  const headerAndBodyContent = splitHeaderAndBodyContent(properties, schema, uiSchema);

  const isLocalized = uiSchema["ui:field"] === "LocalizedField" || uiSchema.blocks?.["ui:field"] === "LocalizedField";

  return (
    <Container>
      <CollapseBar
        expanded={expanded}
        setExpanded={setExpanded}
        title={title}
        description={description}
        titleAddon={titleAddon}
        settingsContent={
          <SettingsContentContainer onClick={(e) => e.stopPropagation()}>
            {headerAndBodyContent.header.map((prop) => prop.content)}
          </SettingsContentContainer>
        }
        isLocalized={isLocalized}
      >
        <GridTemplateContainer>{headerAndBodyContent.body.map((prop) => prop?.content)}</GridTemplateContainer>
      </CollapseBar>
    </Container>
  );
};

export default SectionObjectTemplate;
