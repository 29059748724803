import { Add } from "@mui/icons-material";
import { Button, DialogActions, styled } from "@mui/material";
import type { JSONSchema7 } from "json-schema";
import type { FC } from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import placeholderImage from "@/assets/img/placeholder_image_small.svg";
import { useMediaSelector } from "@/components/MediaSelector/MediaSelector";
import { FullScreenDialog, ModalWrapper } from "@/components/MediaSelector/MediaTypeTabPanel";
import type Document from "@/declarations/models/Document";
import type DocumentRelation from "@/declarations/models/DocumentRelation";
import type Media from "@/declarations/models/Media";
import { useEditorStore } from "@/EditorContextProvider";
import CollapseBar from "@/framework/KioForm/common/CollapseBar";
import ItemBar from "@/framework/KioForm/common/ItemBar";
import type { CustomArrayFieldTemplateProps } from "@/framework/KioForm/templates/array/ArrayFieldTemplate";
import ArrayItemSettingsFields from "@/framework/KioForm/templates/array/ArrayItemSettingsFields";
import Loader from "@/framework/Loader";
import { useLoadingState } from "@/hooks/useLoadingState";
import { useLookup } from "@/hooks/useLookup";
import Api from "@/services/Api";
import Settings from "@/Settings";
import { hideSettingsWidgets } from "@/utils/form";
import { resolvePotentiallyLocalizedString } from "@/utils/obj";
import { getSchemaTitle, getValueFromUiSchema } from "@/utils/schema";
import EditDocumentLoader from "@/views/cms/EditorView/EditDocumentLoader";

type objectAny = { [key: string]: any };
const isItemEmptyish = (element: any) => {
  // TODO add type ArrayFieldTemplateProps["items"] does not work
  const properties = element.children.props?.schema?.properties;
  if (!!properties) {
    // in case of document references
    if (properties.hasOwnProperty("to_document_id")) {
      return Object.keys(properties).length < 3;
    }
    return !Object.keys(properties).length;
  }
  return false;
};

function addOnTitleChangeHandler(onTitleChange: (t: string) => void) {
  return (a: { props?: any }) => ({
    ...a,
    props: {
      ...a.props,
      onTitleChange,
    },
  });
}

function getMediaItemByPath(item: objectAny, path: string): objectAny {
  const tmpPath: string[] = path.split(".");
  return tmpPath.reduce((accPath: objectAny, subPath): objectAny => accPath[subPath], item);
}

async function fetchMedia(newMediaIds: number[]) {
  let newMedia: Media[] = [];
  const getMedia = Api.getMediaUrls({ m: newMediaIds });
  await getMedia
    .fetchDirect(null)
    .then((media) => (media?.items || []) as Media[])
    .then((items) => (newMedia = !!items ? items : []));
  getMedia.abort();
  return newMedia;
}

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(2, 0, 0),
  "& .MuiButton-label": {
    textTransform: "none",
    fontWeight: 700,
  },
}));

const ArrayItems: FC<CustomArrayFieldTemplateProps> = ({
  items,
  canAdd,
  onAddClick,
  formData,
  formContext,
  schema,
  uiSchema,
  onChange,
}) => {
  const { t } = useTranslation("common");
  const { updateDocumentRelation } = useEditorStore();
  const getPotentiallyLocalizedString = resolvePotentiallyLocalizedString(
    formContext.selectedLocale || Settings.DEFAULT_LOCALE
  );

  const [showModal, setShowModal] = useState(false);
  const [selectedSchemaId, setSelectedSchemaId] = useState<number | undefined>(undefined);
  const [documentId, setDocumentId] = useState<number>();

  const itemsExpandedState = useLookup<boolean>();
  const containerRef = useRef<HTMLDivElement>(null);

  const uiOptions = uiSchema.items?.["ui:options"] || uiSchema["ui:options"];
  const mediaPathOption: string = uiOptions?.mediaPath;
  const compactItems: boolean = uiOptions?.compact || false;
  const addButtonLabel: string = uiOptions?.addButton?.["ui:label"] || t("generic.addNew");
  const [mediaObjects, setMediaObjects] = useState<Media[]>([]);
  const [initialized, setInitialized] = useState<boolean>(false);
  const { isLoading, startLoading, stopLoading } = useLoadingState();
  const storedTitleByKey = useLookup<string>();

  const fieldType = useMemo(
    () => getValueFromUiSchema("field", uiSchema.items) || getValueFromUiSchema("field", uiSchema) || "",
    [uiSchema]
  );
  const isMedia = ["MediaSelectorField", "DMSelectorField"].includes(fieldType);
  const isDocumentRelation = fieldType === "DocumentRelation";
  const { isOpen, openMediaDrawer, closeMediaDrawer } = useMediaSelector();
  const [isDirty, setIsDirty] = useState<boolean>(false);

  function toggleMediaDrawer() {
    isOpen
      ? closeMediaDrawer()
      : openMediaDrawer(
          fieldType === "DMSelectorField" ? ["dm"] : uiOptions?.mediaTypes,
          handleMediaSelected,
          true,
          fieldType === "MediaSelectorField" ? formData.map((i: { mediaId: any }) => String(i.mediaId)) : undefined
        );
  }

  async function handleMediaSelected(media: Media | Media[]) {
    if (isMedia && !!onChange) {
      if (Array.isArray(media)) {
        await onChange([...formData, ...media.map((m) => ({ mediaId: m.id }))]);
        closeMediaDrawer();
      } else {
        await onChange([...formData, { mediaId: media.id }]);
        closeMediaDrawer();
      }
    }
  }

  function onEdit(element: any) {
    const relation: DocumentRelation = element?.children?.props?.documentRelation;
    if (relation) {
      setSelectedSchemaId(relation.schema_id || element?.children?.props?.schema_id);
      setDocumentId(relation.document_id);
      setShowModal(true);
    }
  }

  const updateRelations = async (doc?: Document) => {
    if (doc?.id) {
      const getRelation = Api.getDocumentAsRelation(doc.id);
      getRelation
        .fetchDirect(null)
        .then((rel) => {
          rel && updateDocumentRelation(rel);
        })
        .finally(getRelation.abort);
    }
  };

  const getTitleFromFormData = (item: any) => {
    if (typeof item === "string" || typeof item === "number" || typeof item === "boolean") return String(item);
    if (item.title) {
      const title = getPotentiallyLocalizedString(item.title);
      if (title) return title;
    }
    const name = getPotentiallyLocalizedString(item.name);
    // Special case for credits in media editor
    if (!!item.credit_type) {
      const creditTitle = t(`editMedia.creditTypes.${item.credit_type}`);
      if (!!name) return `${creditTitle}: ${name}`;
      return creditTitle;
    }
    return name || getSchemaTitle(formContext, schema?.items as JSONSchema7, uiSchema as JSONSchema7, item);
  };

  function getItemTitle(element: any, media?: Media) {
    if (isDocumentRelation) {
      const relation = element.children?.props?.documentRelation;
      if (relation?.internal_title) return relation.internal_title;
      const title = getPotentiallyLocalizedString(relation?.title);
      if (title) return title;
    } else if (isMedia) {
      if (media?.name) return media.name;
      const mediaTitle = getPotentiallyLocalizedString(media?.title);
      if (mediaTitle) return mediaTitle;
    }
    const storedTitle = storedTitleByKey.getItem(element.key);
    if (storedTitle) return storedTitle;
    const item = formData?.[element.index];
    let itemTitle = "";
    if (item) {
      // first get title to check if it exists
      itemTitle = getTitleFromFormData(item);
    }
    if (mediaPathOption && !itemTitle) {
      // use mediatitle if title does not exist
      if (media?.name) return media.name;
      const mediaTitle = getPotentiallyLocalizedString(media?.title);
      if (mediaTitle) return mediaTitle;
    }
    if (item) return itemTitle;
  }

  function getDescription(element: any) {
    if (isDocumentRelation) {
      const relation = element.children?.props?.documentRelation;
      if (relation?.description && typeof relation.description === "string")
        return t(`presentationType.${relation.description}`);
    }
    const item = formData?.[element.index];
    return getPotentiallyLocalizedString(item?.description) ?? undefined;
  }

  function getTitleAddon(element: any) {
    if (isDocumentRelation) {
      const relation = element.children?.props?.documentRelation;
      if (relation?.status) return `(${t(`status.${relation.status}`)})`;
    }
  }

  const fetchMediaCallback = useCallback(
    (initialized: boolean, mediaPath: string = "") => {
      if (initialized) {
        const filteredOldMedia = mediaObjects.filter((media) =>
          formData.some((item: objectAny) =>
            mediaPath ? getMediaItemByPath(item, mediaPath)?.mediaId === media.id : item?.mediaId === media.id
          )
        );
        const newMediaIds = formData
          .map((item: objectAny) => (mediaPath ? getMediaItemByPath(item, mediaPath)?.mediaId : item?.mediaId))
          .filter((mediaId: number) => mediaId && !mediaObjects.some((media: Media) => media.id === mediaId));
        if (newMediaIds.length) {
          startLoading();
          fetchMedia(newMediaIds)
            .then((newMedia) => {
              setMediaObjects([...filteredOldMedia, ...newMedia]);
            })
            .finally(stopLoading);
        } else if (filteredOldMedia.length !== formData.length) {
          setMediaObjects(filteredOldMedia);
        }
        // initialize mediaObjects from formData
      } else {
        const mediaIds = formData
          .map((item: { [key: string]: any }) =>
            mediaPath ? getMediaItemByPath(item, mediaPath)?.mediaId : item?.mediaId
          )
          .filter((mediaId: number) => !!mediaId);
        if (mediaIds.length) {
          startLoading();
          fetchMedia(mediaIds)
            .then((media) => {
              setMediaObjects(media);
            })
            .finally(() => {
              stopLoading();
              setInitialized(true);
            });
        } else setInitialized(true);
      }
    },
    [formData]
  );

  useEffect(() => {
    if (mediaPathOption) fetchMediaCallback(initialized, mediaPathOption);
    // update mediaObjects from changes in formData
    else if (isMedia) fetchMediaCallback(initialized);
    else setInitialized(true);
  }, [formData]);

  function onDelete(element: any) {
    if (window.confirm(t("components.list.confirmDelete"))) return element.onDropIndexClick(element.index)();
  }

  const closeModal = () => {
    if (!isDirty || window.confirm(t("generic.unsavedChangesMessage"))) setShowModal(false);
  };

  return (
    <>
      <div ref={containerRef}>
        {initialized || !isLoading ? (
          formData?.length > 0 &&
          (items || []).map((element, i) => {
            let media: Media | undefined;
            if (isDocumentRelation) media = element.children?.props?.documentRelation?.media;
            else if (mediaObjects.length && mediaPathOption) {
              media = mediaObjects.find(
                (mediaObject) => mediaObject?.id === getMediaItemByPath(formData[i], mediaPathOption)?.mediaId
              );
            } else if (mediaObjects.length) {
              media = mediaObjects.find((mediaObject) => mediaObject?.id === formData[i]?.mediaId);
            }
            const thumbSrc =
              isMedia || isDocumentRelation || mediaPathOption ? media?.thumbnail_src || placeholderImage : undefined;
            const isFromSharedInstance =
              (isDocumentRelation && element?.isFromSharedInstance) ||
              (isMedia &&
                !!media?.application_instance_id &&
                media?.application_instance_id !== formContext.applicationInstanceId);
            const applicationInstanceId =
              (isDocumentRelation && element.children?.props?.documentRelation?.application_instance_id) ||
              (isMedia && media?.application_instance_id);
            if (compactItems) {
              return (
                <ItemBar
                  key={`${element.key}`}
                  title={getItemTitle(element, media)}
                  thumbnailImageSrc={thumbSrc}
                  onDelete={() => onDelete(element)}
                  onMoveUp={element.onReorderClick(element.index, element.index - 1)}
                  onMoveDown={element.onReorderClick(element.index, element.index + 1)}
                  enableDelete={element.hasRemove}
                  enableMovable={element.hasMoveUp || element.hasMoveDown}
                  moveUpButtonDisabled={!element.hasMoveUp}
                  moveDownButtonDisabled={!element.hasMoveDown}
                  enableSettings={isDocumentRelation && element.hasToolbar}
                  onSettingsClick={() => onEdit(element)}
                  settingsContent={<ArrayItemSettingsFields element={element.children} />}
                  isFromSharedInstance={isFromSharedInstance}
                  sharedInstanceId={applicationInstanceId}
                  selectedLocale={formContext.selectedLocale}
                >
                  {addOnTitleChangeHandler((title) => storedTitleByKey.setItem(element.key, title))(
                    hideSettingsWidgets(element.children)
                  )}
                </ItemBar>
              );
            }

            return (
              <CollapseBar
                key={`${element.key}`}
                title={getItemTitle(element, media)}
                description={getDescription(element)}
                titleAddon={getTitleAddon(element)}
                disableExpansion={isItemEmptyish(element)}
                expanded={!!itemsExpandedState.getItem(element.key)}
                setExpanded={(e) => itemsExpandedState.setItem(element.key, e)}
                onDelete={() => onDelete(element)}
                onMoveUp={element.onReorderClick(element.index, element.index - 1)}
                onMoveDown={element.onReorderClick(element.index, element.index + 1)}
                enableDelete={element.hasRemove}
                enableMovable={element.hasMoveUp || element.hasMoveDown}
                moveUpButtonDisabled={!element.hasMoveUp}
                moveDownButtonDisabled={!element.hasMoveDown}
                enableSettings={isDocumentRelation && element.hasToolbar && !element.isFromSharedInstance}
                onSettingsClick={() => onEdit(element)}
                settingsContent={<ArrayItemSettingsFields element={element.children} />}
                thumbnailImageSrc={thumbSrc}
                isFromSharedInstance={isFromSharedInstance}
                sharedInstanceId={applicationInstanceId}
                selectedLocale={formContext.selectedLocale}
              >
                {addOnTitleChangeHandler((title) => storedTitleByKey.setItem(element.key, title))(
                  hideSettingsWidgets(element.children)
                )}
              </CollapseBar>
            );
          })
        ) : (
          <Loader loadingText="views.cms.loadingContent" />
        )}
      </div>
      {canAdd && (
        <>
          <StyledButton
            variant="outlined"
            color="primary"
            onClick={isMedia ? toggleMediaDrawer : onAddClick}
            startIcon={<Add />}
          >
            {addButtonLabel}
          </StyledButton>
        </>
      )}
      <FullScreenDialog maxWidth={"xl"} fullWidth open={showModal} scroll={"paper"}>
        <ModalWrapper>
          <EditDocumentLoader
            schemaId={selectedSchemaId}
            documentIdProp={documentId}
            modalEditor
            disablePreview
            isDirty={isDirty}
            setIsDirty={setIsDirty}
            onSave={updateRelations}
          />
        </ModalWrapper>
        <DialogActions>
          <Button type="button" color="primary" variant="contained" onClick={closeModal}>
            {t("generic.close")}
          </Button>
        </DialogActions>
      </FullScreenDialog>
    </>
  );
};

export default ArrayItems;
