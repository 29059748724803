import { styled } from "@mui/material";
import type { FC, ReactNode } from "react";
import { memo } from "react";
import CustomIcons from "@/components/CustomIcons";
import type { CustomIconNames } from "@/components/CustomIcons";
import * as IconPaths from "@/assets/icons/material-icons";
import { capitalize } from "@/utils/strings";

export interface IconProps {
  children: string | ReactNode;
  onClick?: () => void;
  /** Rotate in degrees **/
  rotate?: string;
  className?: string;
}

const Container = styled("div")<Omit<IconProps, "icon">>`
  transform: rotate(${(props) => props?.rotate || 0}deg);
  line-height: 0;
  svg {
    fill: currentColor;
  }
`;

export const MuiIcon: FC<IconProps> = ({ children, className, ...rest }) => {
  if (typeof children === "string") {
    /** Custom Icon */
    if (children.includes("Custom:")) {
      return (
        <Container {...rest}>
          <CustomIcons name={children.substring(8, children.length) as CustomIconNames} />
        </Container>
      );
    }

    /** Mui Icon */
    const imgSrc =
      IconPaths[children as keyof typeof IconPaths] ||
      IconPaths[capitalize(children) as keyof typeof IconPaths] ||
      IconPaths.QuestionMark;

    return (
      <Container {...rest}>
        <svg data-loading="lazy" data-src={imgSrc} width="24" height="24"></svg>
      </Container>
    );
  }

  return <Container {...rest}>{children}</Container>;
};

export default memo(MuiIcon);
