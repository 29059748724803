import type { FC } from "react";
import Image from "@/components/Image";
import type { FilterProps } from "@/components/MediaSelector/ColorFiltersModal";
import type { MediaSelectorFieldsetImageDimensions } from "@/framework/KioForm/fields/MediaSelectorField";

export interface ImagePreviewProps {
  url?: string;
  name?: string;
  setImageDimensions?: MediaSelectorFieldsetImageDimensions;
  width?: string | number;
  height?: string | number;
  filterProps?: FilterProps;
}

export const ImagePreview: FC<ImagePreviewProps> = ({
  url,
  name = "image",
  setImageDimensions,
  width,
  height,
  filterProps,
}) => {
  if (!url) {
    return null;
  }
  return (
    <Image
      src={url}
      alt={name}
      width={width}
      height={height}
      setImageDimensions={setImageDimensions}
      isWebPFormat
      filterProps={filterProps}
    />
  );
};

export default ImagePreview;
