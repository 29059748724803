import type { FC } from "react";
import { useMemo } from "react";
import type { TypographyProps } from "@mui/material";
import { styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export interface TimeDiffLabelProps {
  datetime: string | number | Date | null;
  name?: string;
  timePartVariant?: TypographyProps["variant"];
  namePartVariant?: TypographyProps["variant"];
  timePartColor?: TypographyProps["color"];
  namePartColor?: TypographyProps["color"];
}

type DateTimeDiff = {
  years: number;
  months: number;
  weeks: number;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  invalid: boolean;
};

const ONE_SECOND = 1000;
const ONE_MINUTE = ONE_SECOND * 60;
const ONE_HOUR = ONE_MINUTE * 60;
const ONE_DAY = ONE_HOUR * 24;
const ONE_WEEK = 7 * ONE_DAY;
const ONE_MONTH = ONE_WEEK * 4;
const ONE_YEAR = ONE_MONTH * 12;

const LabelSpan = styled(Typography)({
  display: "flex",
  flexFlow: "row wrap",
  columnGap: "0.2em",
  alignItems: "center",
});

export const TimeDiffLabel: FC<TimeDiffLabelProps> = ({
  datetime,
  name = "",
  timePartVariant = "caption",
  timePartColor = "textSecondary",
  namePartVariant = "caption",
  namePartColor = "textSecondary",
}) => {
  const { t } = useTranslation("common");

  const { years, months, weeks, days, hours, minutes, seconds, invalid } = useMemo<DateTimeDiff>(() => {
    const diff: DateTimeDiff = {
      years: 0,
      months: 0,
      weeks: 0,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      invalid: false,
    };
    try {
      const now = new Date();
      const then = new Date(datetime!);
      if (then.getTime() <= now.getTime()) {
        let diffMs: number = now.getTime() - then.getTime();
        const calc = (divisor: number): number => {
          const res = Math.floor(diffMs / divisor);
          diffMs -= res * divisor;
          return res;
        };
        // DON'T REORDER:
        diff.years = calc(ONE_YEAR);
        diff.months = calc(ONE_MONTH);
        diff.weeks = calc(ONE_WEEK);
        diff.days = calc(ONE_DAY);
        diff.hours = calc(ONE_HOUR);
        diff.minutes = calc(ONE_MINUTE);
        diff.seconds = calc(ONE_SECOND);
      }
    } catch (e) {
      console.error(datetime, "is not a valid date", e);
      diff.invalid = true;
    }
    return diff;
  }, [datetime]);

  const renderLabel = (label: string, count: number) => {
    const dateTimeString = t(label, { count });
    const byNameString = !!name ? t("components.TimeDiffLabel.by", { name }) : "";
    return (
      <LabelSpan>
        {!!dateTimeString && (
          <Typography variant={timePartVariant} color={timePartColor} noWrap style={{ fontWeight: 700 }}>
            {dateTimeString}
          </Typography>
        )}
        {!!byNameString && (
          <Typography variant={namePartVariant} color={namePartColor} noWrap>
            {byNameString}
          </Typography>
        )}
      </LabelSpan>
    );
  };

  if (invalid) {
    return <>invalid time</>;
  }

  if (years > 0) {
    return renderLabel("components.TimeDiffLabel.year", years);
  }
  if (months > 0) {
    return renderLabel("components.TimeDiffLabel.month", months);
  }
  if (weeks > 0) {
    return renderLabel("components.TimeDiffLabel.week", weeks);
  }
  if (days > 0) {
    if (days === 1) {
      return renderLabel("components.TimeDiffLabel.yesterday", days);
    }
    if (days === 2) {
      return renderLabel("components.TimeDiffLabel.dayBeforeLast", days);
    }
    return renderLabel("components.TimeDiffLabel.day", days);
  }
  if (hours > 0) {
    return renderLabel("components.TimeDiffLabel.hour", hours);
  }
  if (minutes > 0) {
    return renderLabel("components.TimeDiffLabel.minute", minutes);
  }
  if (seconds >= 0) {
    return renderLabel("components.TimeDiffLabel.second", seconds);
  }
  return <></>;
};

export default TimeDiffLabel;
